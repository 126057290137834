import {
	IonCard,
	IonCardContent,
	IonCol,
	IonContent,
	IonFooter,
	IonGrid,
	IonHeader,
	IonRow,
	IonText
} from '@ionic/react';
import React from 'react';

import './AppDownloadView.css';

const AppDownloadView: React.FC = () => {
	return (
		<IonContent class="appdownloadview-content justify-content-center">
			<IonGrid class="appdownloadview-contentgrid">
				<IonRow class="appdownloadview-contentrow">
					<IonCol
						offsetXl="3"
						sizeXl="6"
						offsetLg="3"
						sizeLg="6"
						offset="3"
						size="6"
						offsetSm="2"
						sizeSm="8"
						offsetXs="1"
						sizeXs="10"
					>
						<div className="appdownloadview-maincontentdiv">
							<IonText>
								<h2>MyLeads Mobile App</h2>
								<h4>by CONEXSYS</h4>
							</IonText>
							<IonCard class="appdownloadview-maincard">
								<div className="appdownloadview-applogobox"></div>
								<IonCardContent>
									<IonText className="appdownloadview-subtext">
										Capture,qualify and follow-up event leads with ease.
									</IonText>
									<div className="appdownloadview-appstorebuttons">
										<a
											className="appdownloadview-applestorebtn"
											href="https://apps.apple.com/us/app/myleads-mobile/id6469713850?platform=iphone"
										></a>
										<a
											className="appdownloadview-googlestorebtn"
											href="https://play.google.com/store/apps/details?id=com.conexsys.myleadsapp"
										></a>
									</div>
								</IonCardContent>
							</IonCard>
							<IonText className="appdownloadview-footertext">
								for more info please visit{' '}
								<a href="https://conexsys.com">conexsys.com</a>
							</IonText>
						</div>
					</IonCol>
				</IonRow>
			</IonGrid>

			<div className="appdownloadview-footerimage"></div>
		</IonContent>
	);
};

export default AppDownloadView;
