import React from 'react';

import {
	IonButton,
	IonItem,
	IonLabel,
	IonGrid,
	IonRow,
	IonCol,
	IonCheckbox,
	IonBadge
} from '@ionic/react';

import { AdminServiceObject } from '../../views/AdminServicesView';

export interface AdminServiceListItemProps {
	serviceObject: AdminServiceObject;
	isSelectable?: boolean;
	checked?: boolean;
	onItemClicked?: (selectedId: string) => void;
	onCheckBoxClicked?: (val: string, checked: boolean) => void;
}

export const AdminServiceListItem: React.FC<AdminServiceListItemProps> = props => {
	//Actions
	const itemClicked = (e: any) => {
		e.stopPropagation();
		if (props.onItemClicked !== undefined) {
			props.onItemClicked(props.serviceObject.service.id);
		}
	};

	const checkBoxClicked = (e: any) => {
		e.stopPropagation();
		e.preventDefault(); //Stops the onItemClick from running
		if (props.onCheckBoxClicked !== undefined) {
			props.onCheckBoxClicked(
				props.serviceObject.service.id,
				e.currentTarget.checked
			);
		}
	};

	//Display
	const mobileAppLicenses: number = props.serviceObject.licenses
		? props.serviceObject.licenses
				.filter(license => license.serviceType === 'lead-app')
				.reduce((sum, current) => sum + current.quantity, 0)
		: 0;
	const mobileAppLicensesActivated: number = props.serviceObject.licenses
		? props.serviceObject.licenses
				.filter(license => license.serviceType === 'lead-app' && license.uses > 0)
				.reduce((sum, current) => sum + current.uses, 0)
		: 0;
	const apiLicenses: number = props.serviceObject.licenses
		? props.serviceObject.licenses
				.filter(license => license.serviceType === 'lead-api')
				.reduce((sum, current) => sum + current.quantity, 0)
		: 0;
	const opticonLicenses: number = props.serviceObject.licenses
		? props.serviceObject.licenses
				.filter(license => license.serviceType === 'opticon')
				.reduce((sum, current) => sum + current.quantity, 0)
		: 0;
	const sstLicenses: number = props.serviceObject.licenses
		? props.serviceObject.licenses
				.filter(license => license.serviceType === 'sst')
				.reduce((sum, current) => sum + current.quantity, 0)
		: 0;

	//Render
	return (
		<IonItem
			button={true}
			key={'AdminServiceListItem_' + props.serviceObject.service.id}
			onClick={itemClicked}
			routerLink="/admin/service"
		>
			<IonGrid>
				<IonRow>
					<IonCol hidden={!props.isSelectable} size="1">
						<IonCheckbox
							hidden={!props.isSelectable}
							checked={props.checked}
							onClick={checkBoxClicked}
						/>
					</IonCol>
					<IonCol size="10" sizeMd="5">
						<IonLabel>{props.serviceObject.service.name}</IonLabel>
						<div
							style={{
								fontSize: '13px',
								fontWeight: 200
							}}
							hidden={!props.serviceObject.contact}
						>
							{props.serviceObject.contact?.address1}{' '}
							{props.serviceObject.contact?.address2}
							<br />
							{props.serviceObject.contact?.city}{' '}
							{props.serviceObject.contact?.subdivision}{' '}
							{props.serviceObject.contact?.postal}
							<br />
							{props.serviceObject.contact?.contactEmail}
							<br />
						</div>
					</IonCol>
					<IonCol>
						<div hidden={(apiLicenses || 0) <= 0}>
							<IonBadge color="success">Lead Api : {apiLicenses}</IonBadge>
						</div>
						<div hidden={(mobileAppLicenses || 0) <= 0}>
							<IonBadge color="primary">
								Lead App : {mobileAppLicensesActivated}/{mobileAppLicenses}
							</IonBadge>
						</div>
						<div hidden={(opticonLicenses || 0) <= 0}>
							<IonBadge color="secondary">Opticon : {opticonLicenses}</IonBadge>
						</div>
						<div hidden={(sstLicenses || 0) <= 0}>
							<IonBadge color="light">SST : {sstLicenses}</IonBadge>
						</div>
						<div
							hidden={
								(sstLicenses || 0) > 0 ||
								(apiLicenses || 0) > 0 ||
								(mobileAppLicenses || 0) > 0 ||
								(opticonLicenses || 0) > 0
							}
						>
							<IonBadge color="warning">No Licneses</IonBadge>
						</div>
					</IonCol>
					<IonCol>
						<IonButton routerLink="/admin/service" onClick={itemClicked}>
							Manage
						</IonButton>
					</IonCol>
				</IonRow>
			</IonGrid>
		</IonItem>
	);
};
