import { useSelector, useDispatch } from 'react-redux';
import EntityHelper from '../../../storage/classes/Entity';
import {
	IEntityHelper,
	EntityHelperOpts,
	entityHelperDefaultOpts
} from '../../../storage';
import {
	getOrderCollection,
	OrderId,
	OrderIds,
	OrderId_Some,
	OrderEntity,
	OrderEntities,
	OrderEntity_Some,
	OrderEntityPatch_Some,
	OrderCollection,
	OrderCollectionState,
	IOrderActions,
	orderActions,
	OrderActionTypes,
	ReadCnxLeadsOrdersRequest
} from '..';
import {
	readOrders,
	readOrdersAll,
	writeOrders,
	ReadOrdersRequest,
	ReadOrdersAllRequest,
	WriteOrdersRequest,
	OrderApiOperation,
	readCnxLeadsOrderByCode,
	readContextsAll,
	readContacts,
	writeContacts,
	readOrderByCode,
	readServicesAll,
	writeServices,
	readLicensesAll,
	writeLicenses,
	readCnxLeadsOrders
} from '../apis';
import { UseCtx } from '../../../config/hooks';
import {
	ContactEntities,
	ContactEntity,
	ContactOrganizationEntity,
	ContextEntities,
	ContextEntity,
	LicenseEntities,
	ServiceEntities,
	ServiceEntity
} from '../collections';
import {
	ContactType,
	LicenseServiceType,
	LicenseType,
	ObjectType,
	OrderCnxLeads,
	OrdersCnxLeads,
	ServiceType
} from '../models';
import { newEntity } from '../../../app/utils';
import _ from 'lodash';
import uuidV4 from 'uuid/v4';
import moment from 'moment';
import { ApiHelper } from '../../../app/store';

export interface OrderHelperActivateResults {
	context: ContextEntity;
	order: OrderEntity;
	contact: ContactEntity;
	service: ServiceEntity;
	licenses: LicenseEntities;
}

/**
 * Order helper interface
 *
 * @export
 * @interface IOrderHelper
 * @extends {IEntityHelper}
 */
export interface IOrderHelper extends IEntityHelper {
	// customProperty: any;
	// customMethod(): any;
	// Custom functions
}

/**
 * Order helper options interface
 *
 * @export
 * @interface OrderHelperOpts
 * @extends {EntityHelperOpts}
 */
export interface OrderHelperOpts extends EntityHelperOpts {
	// customOpt: any;
}

const orderHelperOpts: OrderHelperOpts = {
	...entityHelperDefaultOpts,
	...{}
};

/**
 * Order helper
 *
 * @export
 * @class OrderHelper
 * @extends {EntityHelper<OrderCollection, OrderActionTypes, OrderActions, OrderEntity, OrderEntities, OrderEntity_Some, OrderEntityPatch_Some, OrderId, OrderIds, OrderId_Some, OrderCollectionState, OrderHelperOpts>}
 * @implements {IOrderHelper}
 */
export class OrderHelper
	extends EntityHelper<
		OrderCollection,
		OrderActionTypes,
		IOrderActions,
		OrderEntity,
		OrderEntities,
		OrderEntity_Some,
		OrderEntityPatch_Some,
		OrderId,
		OrderIds,
		OrderId_Some,
		OrderCollectionState,
		OrderHelperOpts
	>
	implements IOrderHelper {
	constructor() {
		super(
			useSelector(getOrderCollection),
			orderActions,
			useDispatch(),
			orderHelperOpts
		);
		this.collection = useSelector(getOrderCollection);
		this.dispatch = useDispatch();
	}

	lastSuccess(operationId: OrderApiOperation, requestId: string = 'default') {
		return this.filter(
			entity =>
				!!entity.__state?.api?.operations?.[operationId]?.[requestId]?.success
					?.last?.dt
		).reverse()[0]?.__state?.api?.operations?.[operationId]?.[requestId]
			?.success?.last?.dt;
	}

	async read(
		ctx: UseCtx<any>,
		params: Partial<ReadOrdersRequest> = {},
		callback?: any
	): Promise<OrderEntities> {
		if (!ctx.app.user.active()?.userId) return [];
		//params.modifiedFrom =
		//	params.modifiedFrom || this.lastSuccess(OrderApiOperation.readOrders);

		let request: ReadOrdersRequest = {
			...params,
			...{
				userId: ctx.app.user.active()?.userId || ''
			}
		};
		if (request.userId === '') return [];

		let entities: OrderEntities = await readOrders(ctx, request)
			.then((entities: OrderEntities) => {
				if (callback) callback(entities);
				return entities;
			})
			.catch(e => {
				if (callback) callback(e);
				return [];
			});
		return entities;
	}

	async readAll(
		ctx: UseCtx<any>,
		params: Partial<ReadOrdersAllRequest> = {},
		callback?: any
	): Promise<OrderEntities> {
		if (!ctx.app.user.active()?.userId) return [];
		//params.modifiedFrom =
		//	params.modifiedFrom || this.lastSuccess(OrderApiOperation.readOrders);

		let request: ReadOrdersAllRequest = {
			...params,
			...{
				userId: ctx.app.user.active()?.userId || ''
			}
		};
		if (request.userId === '') return [];

		let entities: OrderEntities = await readOrdersAll(ctx, request)
			.then((entities: OrderEntities) => {
				if (callback) callback(entities);
				return entities;
			})
			.catch(e => {
				if (callback) callback(e);
				return [];
			});
		return entities;
	}

	async readAllNonActive(
		ctx: UseCtx<any>,
		params: Partial<ReadCnxLeadsOrdersRequest> = {},
		callback?: any
	): Promise<OrdersCnxLeads> {
		if (!ctx.app.user.active()?.userId) return [];
		//params.modifiedFrom =
		//	params.modifiedFrom || this.lastSuccess(OrderApiOperation.readOrders);

		let request: ReadCnxLeadsOrdersRequest = {
			...params,
			...{
				userId: ctx.app.user.active()?.userId || '',
				eventId: params.eventId || ''
			}
		};
		if (request.userId === '') return [];

		let entities = await readCnxLeadsOrders(ctx, request)
			.then(entities => {
				if (callback) callback(entities);
				return entities;
			})
			.catch(e => {
				if (callback) callback(e);
				return [];
			});
		return entities;
	}

	async write(
		ctx: UseCtx<any>,
		params: Partial<WriteOrdersRequest> = {},
		callback?: any
	): Promise<OrderEntities> {
		if (!ctx.app.user.active()?.userId) return [];
		if (!params.orders) return [];
		let request: WriteOrdersRequest = {
			...params,
			...{
				orders: params.orders,
				userId: ctx.app.user.active()?.userId || ''
			}
		};
		if (request.userId === '') return [];

		let entities: OrderEntities = await writeOrders(ctx, request)
			.then((entities: OrderEntities) => {
				if (callback) callback();
				return entities;
			})
			.catch(e => {
				if (callback) callback(e);
				return [];
			});
		return entities;
	}

	async sendActivateEmail(
		ctx: UseCtx<any>,
		orderCode: string,
		to: string,
		cc?: string,
		bcc?: string,
		onError?: (message: string) => void,
		onSuccess?: (result: any) => void
	): Promise<any> {
		try {
			const response = await ctx.app.api.sendEmail({
				from: 'CONEXSYS Lead Retrieval<no-reply@conexsysleads.com>',
				sender: 'no-reply@conexsysleads.com',
				to,
				cc,
				bcc,
				subject:
					'CONEXSYS Lead Retrieval Service Activation - Order # ' + orderCode,
				html: `
<div>
	<table style="width:600px;margin:0;padding:0;font-family:&quot;ProximaNova&quot;,sans-serif;border-collapse:collapse!important;height:100%!important" align="center" border="0" cellpadding="0" cellspacing="0" height="100%" width="100%" id="m_5446877877469990694m_3643126213743891450bodyTable">
		<tbody>
			<tr>
				<td align="center" valign="top" style="margin:0;padding:20px;font-family:&quot;ProximaNova&quot;,sans-serif;height:100%!important">
					<div>
						<h1>Welcome to MyLeads app!</h1>
						<p>Thank you for signing up. Please verify your email address and activate your order by clicking the following link:</p>
						<p><a href="https://leads.conexsys.com/activate?orderCode=${orderCode}" target="_blank">Activate my Account</a></p>
						<p>If you are having any issues with your account, please don't hesitate to contact us by replying to this mail.</p>
						<p>
							Thanks!
							<br>
							<strong>MyLeads app</strong>
						</p>
						<hr style="border:2px solid #eaeef3;border-bottom:0;margin:20px 0">
						<p style="text-align:center;color:#a9b3bc">
							If you did not make this request, please contact support@conexsys.com.
						</p>
					</div>
				</td>
			</tr>
		</tbody>
	</table>
</div>`
			});

			if (!response) throw 'Response missing from call';

			if (response.status < 200 || response.status >= 300) {
				if (onError) onError(`${response.status}: Email Send Invalid Response`);
			} else {
				if (onSuccess) onSuccess(response.data);
			}
			return response.data;
		} catch (e) {
			console.error(e);
			if (onError)
				onError(`Error attempting to send email: ${e?.message || e}`);
		}
	}

	async activate(
		ctx: UseCtx<any>,
		orderCode: string,
		onError: (message: string) => void,
		onSuccess?: (results: OrderHelperActivateResults) => void
	): Promise<OrderHelperActivateResults | undefined> {
		let userId: string = ctx.app.activeUser?.userId || '';
		if (userId === '') {
			onError(
				'Unable to activate without a valid user account. Please Sign-in'
			);
			return;
		}
		let order: OrderEntity | undefined,
			contact: ContactEntity | undefined,
			service: ServiceEntity | undefined,
			context: ContextEntity | undefined,
			errorMessage: string | undefined;

		//Toast.create({ message: 'working...', duration: 2000 });
		let order_CL = await readCnxLeadsOrderByCode(ctx, {
			userId: userId,
			code: orderCode
		})
			.then((result: OrderCnxLeads | undefined) => {
				return result;
			})
			.catch(e => {
				if (e.response?.status !== 404)
					errorMessage =
						'Order lookup error: ' + e.response?.data?.message || e.message;
				return;
			});

		if (errorMessage) {
			onError(errorMessage);
			return;
		}

		if (!order_CL) {
			onError('Invalid Order Code');
			return;
		}

		console.log('Found Cnx Order Code: ' + order_CL.name || order_CL.code);

		// get the context of the order
		let contexts = (await readContextsAll(ctx, {
			userId: userId,
			eventIds: [order_CL.eventId]
		})
			.then((result: ContextEntities) => {
				return result;
			})
			.catch(e => {
				errorMessage =
					'Context request error: ' + e.response?.data?.message || e.message;
				return;
			})) as ContextEntities;

		if (errorMessage) {
			onError(errorMessage);
			return;
		}

		if (contexts.length === 0) {
			onError('Unable to process order context. Please contact support');
			return;
		}

		context = contexts[0] as ContextEntity;

		// get / create the organization contact for the order
		let contacts = (await readContacts(ctx, {
			userId: userId,
			contactType: ContactType.Organization,
			//postal: order_CL.postal
			name: order_CL.name
			//orPostal: order_CL.postal,
			//orEmail: order_CL.email
		})
			.then((result: ContactEntities) => {
				return result;
			})
			.catch(e => {
				errorMessage =
					'Contact request error: ' + e.response?.data?.message || e.message;
				return;
			})) as ContactOrganizationEntity[];

		if (errorMessage) {
			onError(errorMessage);
			return;
		}

		if (contacts.length === 0) {
			console.log('Adding contact in API');
			contacts = (await writeContacts(ctx, {
				userId: userId,
				contacts: [
					{
						...newEntity(ctx, ObjectType.Contact),
						type: ContactType.Organization,
						name: order_CL.name,
						address1: order_CL.address1,
						address2: order_CL.address2,
						city: order_CL.city,
						subdivision: order_CL.subdivision,
						country: order_CL.country,
						postal: order_CL.postal,
						email: order_CL.email,
						contactEmail: order_CL.email,
						contactFirstName: order_CL.contactFirstName,
						contactLastName: order_CL.contactLastName,
						phone: order_CL.phone,
						fax: order_CL.fax,
						properties: {
							external: order_CL
						}
					}
				]
			})
				.then((result: ContactEntities) => {
					console.log('Created contact');
					console.log(result);
					return result;
				})
				.catch(e => {
					errorMessage =
						'Contact write error: ' + e.response?.data?.message || e.message;
					return;
				})) as ContactOrganizationEntity[];
		} else if (contacts[0]) {
			console.log('Updating contact in API');

			let contact = contacts[0];
			contacts = (await writeContacts(ctx, {
				userId: userId,
				contacts: [
					{
						...contact,
						type: ContactType.Organization,
						email: order_CL.email,
						contactEmail: order_CL.email,
						contactFirstName: order_CL.contactFirstName,
						contactLastName: order_CL.contactLastName,
						contactPhone: order_CL.phone,
						address1: order_CL.address1,
						address2: order_CL.address2,
						city: order_CL.city,
						subdivision: order_CL.subdivision,
						postal: order_CL.postal,
						phone: order_CL.phone,
						fax: order_CL.fax,
						country: order_CL.country
					}
				]
			})
				.then((result: ContactEntities) => {
					console.log('Updated contact');
					console.log(result);
					return result;
				})
				.catch(e => {
					errorMessage =
						'Contact write error: ' + e.response?.data?.message || e.message;
					return;
				})) as ContactOrganizationEntity[];
		}

		if (errorMessage) {
			onError(errorMessage);
			return;
		}

		if (contacts.length === 0) {
			onError('Unable to create organization contact');
			return;
		}
		contact = contacts[0];

		if (!contact) {
			onError('Unable to process order contact');
			return;
		}

		// get and create/update the order
		order = (await readOrderByCode(ctx, {
			userId: userId,
			code: orderCode
		})
			.then((result: OrderEntity | undefined) => {
				return result;
			})
			.catch(e => {
				if (e.response?.status !== 404)
					errorMessage =
						'Order request error: ' + e.response?.data?.message || e.message;
				return;
			})) as OrderEntity | undefined;

		if (errorMessage) {
			onError(errorMessage);
			return;
		}

		console.log('Processing Order in API');

		if (!order) {
			console.log('Adding order in API');
			order = {
				...newEntity(ctx, ObjectType.Order),
				code: order_CL.code,
				total: order_CL.total,
				properties: {
					external: {
						order: {
							[order_CL.code]: order_CL
						}
					}
				}
			};
		}
		order.contextId = context.id;
		order.contactIds = _.union(order.contactIds, [contact.id]);
		order.i_.created.dt = moment(order_CL.date).utc().toISOString();

		let orders = (await writeOrders(ctx, {
			userId: userId,
			orders: [order]
		})
			.then((result: OrderEntities) => {
				return result;
			})
			.catch(e => {
				errorMessage =
					'Order update error: ' + e.response?.data?.message || e.message;
				return;
			})) as OrderEntities;

		if (errorMessage) {
			onError(errorMessage);
			return;
		}

		console.log(orders);

		if (orders.length === 0) {
			onError('Unable to process order');
			return;
		}
		order = orders[0];

		// get and create / update the organization service for the order organization contact
		let services = (await readServicesAll(ctx, {
			userId: userId,
			serviceType: ServiceType.Organization,
			contactIds: [contact.id]
		})
			.then((result: ServiceEntities) => {
				return result;
			})
			.catch(e => {
				errorMessage =
					'Service request error: ' + e.response?.data?.message || e.message;
				return;
			})) as ServiceEntities;

		if (errorMessage) {
			onError(errorMessage);
			return;
		}

		if (services.length === 0) {
			console.log('Adding service in API');
			service = {
				...newEntity(ctx, ObjectType.Service),
				type: ServiceType.Organization,
				name: order_CL.name,
				properties: {
					external: {
						order: {
							[order_CL.code]: order_CL
						}
					}
				}
			};
		} else {
			service = services[0];
		}
		service.contactIds = _.union(service.contactIds, [contact.id]);
		service.orderIds = _.union(service.orderIds, [order.id]);
		service.userIds = _.union(service.userIds, [userId]);
		service.contextIds = _.union(service.contextIds, [context.id]);
		service.eventIds = _.union(service.eventIds, context.eventIds);

		services = (await writeServices(ctx, {
			userId: userId,
			services: [service]
		})
			.then((result: ServiceEntities) => {
				console.log('Upserted service');
				console.log(result);
				return result;
			})
			.catch(e => {
				errorMessage =
					'Service update error: ' + e.response?.data?.message || e.message;
				return;
			})) as ServiceEntities;

		if (errorMessage) {
			onError(errorMessage);
			return;
		}

		if (services.length === 0) {
			onError('Unable to process order service');
			return;
		}
		service = services[0];

		if (
			!(order_CL.licenseQuantityLeadApi || 0 > 0) &&
			!(order_CL.licenseQuantityLeadApp || 0 > 0) &&
			!(order_CL.licenseQuantityOpticon || 0 > 0) &&
			!(order_CL.licenseQuantitySst || 0 > 0)
		) {
			onError('Your order does not contain any valid license purchases.');
			return;
		}

		// get and create / update the licenses for the order
		let licenses = (await readLicensesAll(ctx, {
			userId: userId,
			licenseType: LicenseType.Device,
			orderIds: [order.id]
		})
			.then((result: LicenseEntities) => {
				return result;
			})
			.catch(e => {
				errorMessage =
					'License request error: ' + e.response?.data?.message || e.message;
				return;
			})) as LicenseEntities;

		if (errorMessage) {
			onError(errorMessage);
			return;
		}

		// TODO remove order from old service if changed...  Ideally move users over as well....  based on users assigned to order licenses and check if the user has other licenses on old service.. if not remove them from old service.

		// lead-api license
		if (
			order_CL.licenseQuantityLeadApi &&
			order_CL.licenseQuantityLeadApi > 0
		) {
			let apiLicenses = licenses.filter(
				license => license.serviceType == LicenseServiceType.LeadApi
			);
			if (apiLicenses.length > 0) {
				console.log('Adding user to existing lead-api licenses');
				apiLicenses.forEach(license => {
					license.serviceId = (service as ServiceEntity).id;
					license.userIds = _.union(license.userIds, [userId]);
				});
			} else {
				console.log('Adding lead-api license');
				licenses.push({
					...newEntity(ctx, ObjectType.License),
					type: LicenseType.Device,
					serviceType: LicenseServiceType.LeadApi,
					orderId: order.id,
					contextId: context.id,
					serviceId: service.id,
					userIds: [userId],
					code: uuidV4(),
					name: order_CL.name + ': API',
					serviceName: order_CL.licenseNameLeadApi,
					quantity: order_CL.licenseQuantityLeadApi,
					uses: 0,
					properties: {
						external: {
							order: {
								[order_CL.code]: order_CL
							}
						}
					}
				});
			}
		}
		// lead-app license
		if (
			order_CL.licenseQuantityLeadApp &&
			order_CL.licenseQuantityLeadApp > 0
		) {
			let appLicenses = licenses.filter(
				license => license.serviceType === LicenseServiceType.LeadApp
			);
			if (appLicenses.length > 0) {
				console.log('Adding user to existing lead-app licenses');
				appLicenses.forEach(license => {
					license.serviceId = (service as ServiceEntity).id;
					license.userIds = _.union(license.userIds, [userId]);
				});
			} else {
				console.log('Adding lead-app license');
				licenses.push({
					...newEntity(ctx, ObjectType.License),
					type: LicenseType.Device,
					serviceType: LicenseServiceType.LeadApp,
					orderId: order.id,
					contextId: context.id,
					serviceId: service.id,
					userIds: [userId],
					code: uuidV4(),
					name: order_CL.name + ': App',
					serviceName: order_CL.licenseNameLeadApp,
					quantity: order_CL.licenseQuantityLeadApp,
					uses: 0,
					properties: {
						external: {
							order: {
								[order_CL.code]: order_CL
							}
						}
					}
				});
			}
		}
		// opticon license
		if (
			order_CL.licenseQuantityOpticon &&
			order_CL.licenseQuantityOpticon > 0
		) {
			let opticonLicenses = licenses.filter(
				license => license.serviceType === LicenseServiceType.Opticon
			);
			if (opticonLicenses.length > 0) {
				console.log('Adding user to existing opticon licenses');
				opticonLicenses.forEach(license => {
					license.serviceId = (service as ServiceEntity).id;
					license.userIds = _.union(license.userIds, [userId]);
				});
			} else {
				console.log('Adding opticon license');
				licenses.push({
					...newEntity(ctx, ObjectType.License),
					type: LicenseType.Device,
					serviceType: LicenseServiceType.Opticon,
					orderId: order.id,
					contextId: context.id,
					serviceId: service.id,
					userIds: [userId],
					code: uuidV4(),
					name: order_CL.name + ': Opticon',
					serviceName: order_CL.licenseNameOpticon,
					quantity: order_CL.licenseQuantityOpticon,
					uses: 0,
					properties: {
						external: {
							order: {
								[order_CL.code]: order_CL
							}
						}
					}
				});
			}
		}
		// sst license
		if (order_CL.licenseQuantitySst && order_CL.licenseQuantitySst > 0) {
			let sstLicenses = licenses.filter(
				license => license.serviceType === LicenseServiceType.Sst
			);
			if (sstLicenses.length > 0) {
				console.log('Adding user to existing sst licenses');
				sstLicenses.forEach(license => {
					license.serviceId = (service as ServiceEntity).id;
					license.userIds = _.union(license.userIds, [userId]);
				});
			} else {
				console.log('Adding sst license');
				licenses.push({
					...newEntity(ctx, ObjectType.License),
					type: LicenseType.Device,
					serviceType: LicenseServiceType.Sst,
					orderId: order.id,
					contextId: context.id,
					serviceId: service.id,
					userIds: [userId],
					code: uuidV4(),
					name: order_CL.name + ': SST',
					serviceName: order_CL.licenseNameSst,
					quantity: order_CL.licenseQuantitySst,
					uses: 0,
					properties: {
						external: {
							order: {
								[order_CL.code]: order_CL
							}
						}
					}
				});
			}
		}

		// write new/update licenses
		licenses = (await writeLicenses(ctx, {
			userId: userId,
			licenses
		})
			.then((result: LicenseEntities) => {
				console.log('Upserted license');
				console.log(result);
				return result;
			})
			.catch(e => {
				errorMessage =
					'License update error: ' + e.response?.data?.message || e.message;
				return;
			})) as LicenseEntities;

		if (errorMessage) {
			onError(errorMessage);
			return;
		}

		if (licenses.length === 0) {
			onError('Unable to create order licenses');
			return;
		}
		let results: OrderHelperActivateResults = {
			order,
			context,
			contact,
			service,
			licenses
		};

		if (onSuccess) onSuccess(results);
		return results;
	}
}
