import { RouteEntities } from '../../app/store/collections/Route';
import { ObjectType } from '../../lead/store/models/ObjectType';

export interface RouteConfig {
	entities: RouteEntities;
	activeId?: string;
}

export const routeConfig: RouteConfig = {
	entities: [
		{
			id: '/',
			redirect: '/home'
		},
		{
			id: '/home',
			title: 'Welcome',
			view: 'lead.SplashView',
			security: {
				deny: ['user'],
				denyRedirect: '/main'
			},
			header: {
				title: ''
			}
		},
		{
			id: '/reverselead/:contextId/:contactId',
			view: 'lead.ReverseLeadsView',
			menu: { main: { tags: ['default'] } },
			//security: { allow: ['user'] },
			header: {
				title: 'Reverse Lead'
			}
		},
		{
			id: '/main',
			view: 'lead.MainView',
			menu: { main: { tags: ['default'] } },
			security: {
				allow: ['user'],
				deny: ['administrator'],
				denyRedirect: '/admin/events'
			},
			header: {
				title: 'Home'
			}
		},
		{
			id: '/activate',
			view: 'lead.ActivateView',
			menu: { main: { tags: ['default'] } },
			header: {
				title: 'Activation'
			},
			security: { allow: ['user'] }
		},
		{
			id: '/lead-service/services',
			view: 'lead.ServicesView',
			menu: { main: { tags: ['lead-service'] } },
			header: {
				title: 'Accounts'
			},
			security: { allow: ['user'] }
		},
		{
			id: '/lead-service/service',
			view: 'lead.ServiceView',
			menu: { main: { tags: ['lead-service'] } },
			header: {
				title: 'Account'
			},
			security: { allow: ['user'] }
		},
		{
			id: '/lead-service/orders',
			view: 'lead.OrdersView',
			menu: { main: { tags: ['lead-service'] } },
			header: {
				title: 'Orders'
			},
			security: { allow: ['user'] }
		},
		{
			id: '/lead-service/events',
			view: 'lead.EventsView',
			menu: { main: { tags: ['lead-service'] } },
			header: {
				title: 'Events'
			},
			security: { allow: ['user'] }
		},
		{
			id: '/lead-service/event',
			view: 'lead.EventView',
			menu: { main: { tags: ['lead-service'] } },
			header: {
				title: 'Event'
			},
			security: { allow: ['user'] }
		},
		{
			id: '/lead-service/devices',
			view: 'lead.DevicesView',
			menu: { main: { tags: ['lead-service'] } },
			header: {
				title: 'Devices'
			},
			security: { allow: ['user'] }
		},
		{
			id: '/lead-service/licenses',
			view: 'lead.LicensesView',
			menu: { main: { tags: ['lead-service'] } },
			header: {
				title: 'Licenses'
			},
			security: { allow: ['user'] }
		},
		{
			id: '/lead-service/license',
			view: 'lead.LicenseView',
			menu: { main: { tags: ['lead-service'] } },
			header: {
				title: 'License'
			},
			security: { allow: ['user'] }
		},
		{
			id: '/lead-service/users',
			view: 'lead.UsersView',
			menu: { main: { tags: ['lead-service'] } },
			header: {
				title: 'Representatives'
			},
			security: { allow: ['user'] }
		},
		{
			id: '/lead-service/user',
			view: 'lead.UserView',
			menu: { main: { tags: ['lead-service'] } },
			header: {
				title: 'Representative'
			},
			security: { allow: ['user'] }
		},
		{
			id: '/lead-service/qualifiers',
			view: 'lead.QualifiersView',
			menu: { main: { tags: ['lead-service'] } },
			header: {
				title: 'Qualifiers'
			},
			security: { allow: ['user'] }
		},
		{
			id: '/lead-service/qualifier',
			view: 'lead.QualifierView',
			menu: { main: { tags: ['lead-service'] } },
			header: {
				title: 'Qualifier'
			},
			security: { allow: ['user'] }
		},
		{
			id: '/lead-service/leads',
			view: 'lead.LeadsView',
			menu: { main: { tags: ['lead-service'] } },
			header: {
				title: 'Leads'
			},
			security: { allow: ['user'] }
		},
		{
			id: '/lead-service/lead',
			view: 'lead.LeadView',
			menu: { main: { tags: ['lead-service'] } },
			header: {
				title: 'Lead'
			},
			security: { allow: ['user'] }
		},
		{
			id: '/lead-service/reports',
			view: 'lead.ReportsView',
			menu: { main: { tags: ['lead-service'] } },
			header: {
				title: 'Reports'
			},
			security: { allow: ['user'] }
		},
		{
			id: '/lead-service/tickets',
			view: 'lead.TicketsView',
			menu: { main: { tags: ['lead-service'] } },
			header: {
				title: 'Tickets'
			},
			security: { allow: ['user'] }
		},
		{
			id: '/lead-service/app',
			view: 'lead.LeadAppView',
			menu: { main: { tags: ['lead-service'] } },
			header: {
				title: 'Lead App'
			},
			security: { allow: ['user'] }
		},
		{
			id: '/admin/events',
			view: 'lead.AdminEventsView',
			menu: { main: { tags: ['admin'] } },
			header: {
				title: 'Events Admin'
			},
			chat: {
				disabled: true
			},
			security: { allow: ['administrator'] }
		},
		{
			id: '/admin/event',
			view: 'lead.AdminEventView',
			menu: { main: { tags: ['admin'] } },
			header: {
				title: 'Event Admin'
			},
			chat: {
				disabled: true
			},
			security: { allow: ['administrator'] }
		},
		{
			id: '/admin/users',
			view: 'lead.AdminUsersView',
			menu: { main: { tags: ['admin'] } },
			header: {
				title: 'User Admin'
			},
			chat: {
				disabled: true
			},
			security: { allow: ['administrator'] }
		},
		{
			id: '/admin/orders',
			view: 'lead.AdminOrdersView',
			menu: { main: { tags: ['admin'] } },
			header: {
				title: 'Orders Admin'
			},
			chat: {
				disabled: true
			},
			security: { allow: ['administrator'] }
		},
		{
			id: '/admin/order',
			view: 'lead.AdminOrderView',
			menu: { main: { tags: ['admin'] } },
			header: {
				title: 'Order Admin'
			},
			chat: {
				disabled: true
			},
			security: { allow: ['administrator'] }
		},
		{
			id: '/admin/services',
			view: 'lead.AdminServicesView',
			menu: { main: { tags: ['admin'] } },
			header: {
				title: 'Services Admin'
			},
			chat: {
				disabled: true
			},
			security: { allow: ['administrator'] }
		},
		{
			id: '/admin/service',
			view: 'lead.AdminServiceView',
			menu: { main: { tags: ['admin'] } },
			header: {
				title: 'Service Admin'
			},
			chat: {
				disabled: true
			},
			security: { allow: ['administrator'] }
		},
		{
			id: '/admin/scanners',
			view: 'lead.AdminScannerView',
			menu: { main: { tags: ['admin'] } },
			header: {
				title: 'Scanners Admin'
			},
			chat: {
				disabled: true
			},
			security: { allow: ['administrator'] }
		},
		{
			id: '/account',
			view: 'app.AccountView',
			menu: { main: { tags: ['account'] } },
			header: {
				title: 'Account'
			},
			security: { allow: ['user'] }
		},
		{
			id: '/support',
			view: 'app.SupportView',
			menu: { main: { tags: ['support'] } },
			header: {
				title: 'Support'
			},
			security: { allow: ['user'] }
		},
		{
			id: '/settings',
			view: 'app.SettingsView',
			menu: { main: { tags: ['settings'] } },
			header: {
				title: 'Settings'
			},
			security: { allow: ['user'] }
		},
		{
			id: '/authorize',
			view: 'app.AuthorizeView',
			header: {
				title: 'Authorization'
			}
		},
		{
			id: '/app',
			view: 'lead.AppDownloadView',
			header: {
				title: '',
				hidden: true
			}
		}
	]
};
