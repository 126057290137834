import React, { useEffect, useState } from 'react';
import QRCode from 'react-qr-code';

//DATA
import {
	ServiceEntity,
	ServiceEntities,
	LicenseEntities,
	LicenseEntity
} from '../../store';
import { useCtx } from '../../../config/hooks';
import { ServiceNotFoundCard } from '../components/Service/ServiceNotFoundCard';

//props
export interface AdminPrintLicensesViewProps {
	servicesToPrint?: String[];
}

const AdminPrintLicensesView: React.FC<AdminPrintLicensesViewProps> = props => {
	const ctx = useCtx<{}>({});
	const {
		lead: {
			activeService,
			serviceHelper,
			activeEvent,
			activeContext,
			licenseHelper
		}
	} = ctx;

	const serviceList = serviceHelper
		.allByContextIds([activeContext?.id || ''])
		.filter(ec =>
			props.servicesToPrint && props.servicesToPrint.length > 0
				? props.servicesToPrint?.includes(ec.id)
				: true
		)
		.sort((a: ServiceEntity, b: ServiceEntity) => {
			return (a.name?.toLowerCase() || '') > (b.name?.toLowerCase() || '')
				? 1
				: (b.name?.toLowerCase() || '') > (a.name?.toLowerCase() || '')
				? -1
				: 0;
		})
		.map(service => {
			if (!activeContext || !service) return '';
			let licenses: LicenseEntities = licenseHelper.allByContexts(
				[activeContext],
				[service]
			);
			let licenseItems = licenses
				.sort((a: LicenseEntity, b: LicenseEntity) => {
					return (a.serviceType || '') > (b.serviceType || '')
						? 1
						: (b.serviceType || '') > (a.serviceType || '')
						? -1
						: 0;
				})
				.filter(license => (license.serviceType === 'lead-app' ? true : false))
				.map(license => {
					if (license.serviceType === 'lead-app') {
						return (
							<div key={'lead-app-license' + license.id}>
								<div
									style={{
										width: '20%',
										display: 'inline-block',
										marginTop: '30px'
									}}
								>
									<QRCode
										value={license.code}
										style={{
											height: 'auto',
											maxWidth: '120px',
											width: '120px'
										}}
									/>
								</div>
								<div
									style={{
										width: '30%',
										display: 'inline-block',
										marginTop: '30px'
									}}
								>
									<div>
										<b>Max Usage: {license.quantity}</b>
									</div>
									<br />
									<b>Activation Code:</b>
									<br />
									<div>{license.code}</div>
								</div>
							</div>
						);
					}
				});

			if (licenseItems && licenseItems.length > 0) {
				return (
					<div
						key={'lead-app-service-' + service.id}
						style={{
							margin: '20px',
							pageBreakBefore: 'always'
						}}
					>
						<div
							style={{
								margin: '10px'
							}}
						>
							<div
								style={{
									width: '100%',
									textAlign: 'center',
									display: 'inline-block'
								}}
							>
								<h2>{service.name}</h2>
								<br />
								{activeEvent?.name}
								<br />
								Mobile App Licenses
							</div>
						</div>

						<div
							style={{
								margin: '10px'
							}}
						>
							{licenseItems}
						</div>
					</div>
				);
			} else {
				return '';
			}
		});

	if (!activeContext || (props.servicesToPrint?.length ?? 0) <= 0)
		return <ServiceNotFoundCard />;

	return <>{serviceList}</>;
};

export default AdminPrintLicensesView;
