import React, { useEffect, useState } from 'react';

//DATA
import {
	LeadEntity,
	LeadEntities,
	EventContactEntity,
	Service,
	isObjectStatusActive,
	NoteEntity,
	DataFields,
	readEventContactAnswers,
	DeviceHelper,
	ObjectType
} from '../../../store';
import { useInterval } from '../../../../app/store';
import { useCtx } from '../../../../config/hooks';

//UI
import {
	IonGrid,
	IonRow,
	IonCol,
	IonList,
	IonToolbar,
	IonItemDivider,
	IonLabel,
	IonButton,
	useIonViewWillEnter,
	useIonViewWillLeave,
	IonIcon,
	IonLoading,
	IonProgressBar
} from '@ionic/react';
import { LeadListItem } from '../../components/Lead/LeadListItem';
import { SearchBar } from '../../../../app/ui/components/Search/SearchBar';
import { ListPaging } from '../../../../app/ui/components/ListPaging';
import {
	businessOutline,
	calendarOutline,
	downloadOutline,
	peopleOutline,
	personOutline
} from 'ionicons/icons';

//LIB
import moment from 'moment';
import { saveAs } from 'file-saver';
import * as Excel from 'exceljs';

//STYLE
import './LeadList.css';
import {
	Selector,
	SelectorItem,
	SelectorItems
} from '../../../../app/ui/components/Selector';

export interface Sort {
	value: any;
	direction: SortDirection;
}

export enum SortDirection {
	Ascending = 'ascending',
	Descending = 'descending'
}

type LeadItem = { lead: LeadEntity; contact?: EventContactEntity };

const sortItems: SelectorItems = [
	{
		icon: calendarOutline,
		text: 'Date Scanned',
		value: 'DateScanned'
	},
	{
		icon: personOutline,
		text: 'Last Name',
		value: 'LastName'
	},
	{
		icon: businessOutline,
		text: 'Company',
		value: 'Company'
	}
];
export interface LeadListConfigProps {
	service?: Service;
	title?: string;
	finish?: any;
}

export const LeadList: React.FC<LeadListConfigProps> = props => {
	const [searchText, setSearchText] = useState('');
	const [sort, setSort] = useState<Sort>({
		value: 'DateScanned',
		direction: SortDirection.Descending
	});

	const [pageSize, setPageSize] = useState(15);
	const [pageSelected, setPageSelected] = useState(1);
	const [loading, setLoading] = useState(true);
	const [active, setActive] = useState(true);
	const [currentServiceId, setCurrentServiceId] = useState('');
	const [modifiedFrom, setModifiedFrom] = useState('');
	const [workingStatus, setWorkingStatus] = useState<string | undefined>(
		undefined
	);

	const [startExport, setStartExport] = useState(false);
	const [goExport, setGoExport] = useState(false);
	const [exportItems, setExportItems] = useState<LeadItem[]>([]);
	const [exportLoadingItems, setExportLoadingItems] = useState<LeadItem[]>([]);

	const ctx = useCtx<LeadListConfigProps>({});
	const {
		config: [config],
		lead: {
			activeContext,
			activeEvent,
			activeService,
			leadHelper,
			eventContactHelper,
			noteHelper,
			userHelper,
			deviceHelper,
			responseHelper,
			qualifierHelper,
			qualifierValueHelper
		}
	} = ctx;

	const leadDataFields: DataFields =
		ctx.lead.context
			.active()
			?.leadDataFields?.filter(ldf => ldf.type == ObjectType.EventQuestion) ||
		[];
	const eventQuestions = ctx.lead.eventQuestion.allActive(ctx);

	const read = (next: any) => {
		let modFrom = modifiedFrom !== '' ? modifiedFrom : undefined;
		leadHelper.read(
			ctx,
			{
				modifiedFrom: modFrom,
				eventIds: activeContext?.eventIds ?? []
			},
			() => {
				setLoading(false);
				if (typeof next === 'function') next();
			}
		);
		responseHelper.read(ctx, undefined, () => {});
		noteHelper.read(ctx, undefined, () => {});
		qualifierHelper.read(ctx, undefined, () => {});
		qualifierValueHelper.read(ctx, undefined, () => {});
	};

	useEffect(() => {
		if (
			activeService &&
			currentServiceId !== '' &&
			activeService.id !== currentServiceId
		)
			setModifiedFrom('');

		setCurrentServiceId(activeService?.id || '');
		read(() => {});
	}, [activeService]);

	useEffect(() => {
		if (startExport) {
			exportDataLoad();
			exporting();
		}
	}, [startExport]);

	useEffect(() => {
		if (goExport) {
			setStartExport(false);

			if (!goExport || exportItems.length !== items.length) {
				return;
			}

			setGoExport(false);

			onGoExportLeads();
		}
	}, [goExport]);

	useInterval(
		(next: any, startExport: boolean) => {
			if (startExport) {
				exporting();
			}
			next();
		},
		2000,
		startExport
	);

	useIonViewWillEnter(() => {
		setActive(true);
	});

	useIonViewWillLeave(() => {
		setActive(false);
	});

	//Prevents Ionic cached component instances from actively running in background when not needed.
	if (!active) return <></>;
	if (!activeContext || !activeEvent) return <>No Event selected</>;

	const sortLeads = (a: LeadItem, b: LeadItem) =>
		(
			sort.value === 'LastName'
				? eventContactHelper.getLastName(a.contact) >
				  eventContactHelper.getLastName(b.contact)
				: sort.value === 'Company'
				? eventContactHelper.getOrganizationName(a.contact) >
				  eventContactHelper.getOrganizationName(b.contact)
				: moment(a.lead.i_?.created?.dt).isAfter(moment(b.lead.i_?.created?.dt))
		)
			? sort.direction === SortDirection.Ascending
				? 1
				: -1
			: sort.direction === SortDirection.Ascending
			? -1
			: 1;

	let allLeads: LeadEntities = leadHelper.allByServiceIdAndEventIds(
		props.service?.id || '',
		activeContext.eventIds
	);

	let items: LeadItem[] = allLeads
		.map(lead => ({
			lead,
			contact: eventContactHelper.get(lead.contactId)
		}))
		.filter(item => {
			let match = 0,
				matches = 0,
				keywords = searchText ?? '',
				lead = item.lead,
				contact = item.contact;

			for (let keyword of keywords.toLowerCase().split(' ')) {
				if (keyword === '') break;
				match++;
				let name = eventContactHelper.getName(contact),
					organization = eventContactHelper.getOrganizationName(contact);
				if (
					name?.toLowerCase().trim().indexOf(keyword) > -1 ||
					organization?.toLowerCase().trim().indexOf(keyword) > -1 ||
					(contact?.email &&
						contact?.email?.toLowerCase().trim().indexOf(keyword) > -1) ||
					(contact?.city &&
						contact?.city?.toLowerCase().trim().indexOf(keyword) > -1) ||
					(contact?.postal &&
						contact?.postal?.toLowerCase().trim().indexOf(keyword) > -1) ||
					(contact?.country &&
						contact?.country?.toLowerCase().trim().indexOf(keyword) > -1)
				) {
					matches++;
				}
			}
			if (lead.i_?.modified.dt && lead.i_.modified.dt > modifiedFrom) {
				setModifiedFrom(lead.i_.modified.dt);
			}
			return match === matches;
		});

	let totalLeads = items.length;

	const leadListItems = items
		.sort(sortLeads)
		.slice(pageSize * (pageSelected - 1), pageSize * pageSelected)
		.map((item: LeadItem, index: number, leadItems: LeadItem[]) => {
			let newSortValue = '';
			let lead = item.lead,
				contact = item.contact;
			if (sort.value === 'LastName') {
				let name = eventContactHelper.getLastName(contact);

				if (index > 0) {
					let prevContact = leadItems[index - 1].contact;
					let prevName = eventContactHelper.getLastName(prevContact);
					if (
						(name?.charAt(0).toUpperCase() ?? '') !=
						(prevName?.charAt(0).toUpperCase() ?? '')
					) {
						newSortValue = name?.charAt(0).toUpperCase() ?? '';
					}
				} else {
					newSortValue = name?.charAt(0).toUpperCase() ?? '';
				}
			} else if (sort.value === 'Company') {
				let name = eventContactHelper.getOrganizationName(contact);

				if (index > 0) {
					let prevContact = leadItems[index - 1].contact;
					let prevName = eventContactHelper.getOrganizationName(prevContact);
					if (
						(name?.charAt(0).toUpperCase() ?? '') !=
						(prevName?.charAt(0).toUpperCase() ?? '')
					) {
						newSortValue = name?.charAt(0).toUpperCase() ?? '';
					}
				} else {
					newSortValue = name?.charAt(0).toUpperCase() ?? '';
				}
			} else {
				let leadDate = moment(lead.i_.created?.dt).format('dddd Do MMMM, YYYY');
				if (index > 0) {
					let prevLeadDate = moment(
						leadItems[index - 1].lead.i_.created?.dt
					).format('dddd Do MMMM, YYYY');
					if ((leadDate ?? '') != (prevLeadDate ?? '')) {
						newSortValue = leadDate ?? '';
					}
				} else {
					newSortValue = leadDate ?? '';
				}
			}
			return newSortValue == '' ? (
				<LeadListItem key={lead.id} lead={lead} />
			) : (
				<div key={'div' + lead.id}>
					<IonItemDivider key={'divider' + lead.id}>
						<IonLabel>{newSortValue}</IonLabel>
					</IonItemDivider>
					<LeadListItem key={lead.id} lead={lead} />
				</div>
			);
		});

	const onSerach = (value: string | undefined) => {
		if (value) {
			setPageSelected(1);
			setSearchText(value.trim());
		} else {
			setSearchText('');
		}
	};

	const onSortValueChange = (item: SelectorItem) => {
		setSort({ ...sort, value: item.value || item.text });
	};

	const onSortDirectionChange = (direction: SortDirection) => {
		setSort({ ...sort, direction });
	};

	const onPageChange = (pageMovement: number) => {
		let newPage = pageSelected.valueOf() + pageMovement;
		let maxPage = Math.ceil(totalLeads / pageSize);
		setPageSelected(newPage < 1 ? 1 : newPage > maxPage ? maxPage : newPage);
	};

	const onPageSizeChanged = (pageSize: number) => {
		setPageSize(pageSize);
		setPageSelected(1);
	};

	const onExportLeads = async () => {
		setStartExport(true);
		setWorkingStatus('Exporting...');
	};

	const exportDataLoad = async () => {
		let userId = ctx.app.user.active()?.userId || '';

		if (leadDataFields.length > 0) {
			let missingAnswerItems: LeadItem[] = items.filter(
				item =>
					item.contact &&
					!item.contact.answers &&
					!exportLoadingItems.find(item2 => item2.lead.id === item.lead.id)
			);

			for (let item of missingAnswerItems) {
				if (item.contact) {
					await new Promise(resolve => setTimeout(resolve, 200));
					let contactId = item.contact.id,
						eventId = item.contact.eventId;
					readEventContactAnswers(ctx, { userId, contactId, eventId });
				}
			}
		}
	};

	const exporting = async () => {
		let stillLoadingItems: LeadItem[] = items.filter(
			item =>
				!item.contact || (leadDataFields.length > 0 && !item.contact?.answers)
		);

		setExportLoadingItems(stillLoadingItems);

		if (stillLoadingItems.length !== 0) {
			console.log('stillLoadingItems');
			setExportLoadingItems(stillLoadingItems);
			setExportItems([]);
			setWorkingStatus('Preparing ' + stillLoadingItems.length + ' items...');
			setGoExport(false);
		} else {
			setExportLoadingItems([]);
			setExportItems(items);
			setGoExport(true);
		}
	};

	const onGoExportLeads = async () => {
		setWorkingStatus('Generating File...');

		let workbook = new Excel.Workbook();
		let worksheet = workbook.addWorksheet('Leads');
		let cols = [
			{ header: 'Id', key: 'code' },
			{ header: 'First Name', key: 'firstName' },
			{ header: 'Last Name', key: 'lastName' },
			{ header: 'Designation', key: 'designation' },
			{ header: 'Title', key: 'title' },
			{ header: 'Company', key: 'organization' },
			{ header: 'Address1', key: 'address1' },
			{ header: 'Address2', key: 'address2' },
			{ header: 'City', key: 'city' },
			{ header: 'State/Prov', key: 'subdivision' },
			{ header: 'Postal/Zip', key: 'postal' },
			{ header: 'Country', key: 'country' },
			{ header: 'Email', key: 'email' },
			{ header: 'Phone', key: 'phone' },
			{ header: 'Mobile', key: 'mobile' }
		];

		let dataFields: DataFields = [];

		for (let f = 0; f < leadDataFields.length; f++) {
			let field = leadDataFields[f];
			dataFields.push(field);
			let question = eventQuestions.filter(q => field.id === q.id)[0];
			cols.push({
				header:
					field.name ||
					question?.name ||
					question?.text ||
					field.id ||
					field.key ||
					'Field ' + f + 1,
				key: 'field' + f + 1
			});
		}

		cols.push({ header: 'Date Scanned', key: 'dateScanned' });
		cols.push({ header: 'Scanned By', key: 'scannedBy' });
		cols.push({ header: 'Device', key: 'scanningDevice' });
		cols.push({ header: 'Notes', key: 'notes' });

		let mainColCount = cols.length;
		let allQualifiers = !activeService
			? []
			: qualifierHelper.allByServiceIdAndEventIds(
					activeService.id,
					activeContext.eventIds
			  );
		allQualifiers.forEach(qualifier => {
			cols.push({ header: qualifier.name, key: qualifier.id });
		});
		worksheet.columns = cols;
		worksheet.columns.forEach(column => {
			if (column.header) {
				column.width = column.header.length < 15 ? 15 : column.header.length;
			}
		});

		items.sort(sortLeads).forEach((item, index) => {
			let lead = item.lead,
				contact = item.contact;

			if (!contact) return;

			let fieldData: any = {};

			for (let f = 0; f < dataFields.length; f++) {
				let field = dataFields[f];
				let question = eventQuestions.filter(q => field.id === q.id)[0];

				let answerString = question
					? !contact.answers
						? 'loading...'
						: contact.answers
								.filter(a => a.questionId === question.id)
								.filter(isObjectStatusActive)
								.map((contactAnswer): string | undefined => {
									if (contactAnswer.text && contactAnswer.text !== '') {
										return contactAnswer.text;
									} else if (contactAnswer.answerId) {
										return ctx.lead.eventAnswer.get(contactAnswer.answerId)
											?.value;
									} else {
										return;
									}
								})
								.filter(s => s && s !== '')
								.join(', ') || ''
					: '';

				fieldData['field' + f + 1] = answerString;
			}

			let deviceUsed = deviceHelper.get(lead.i_.created?.on ?? '');
			let deviceInfo = deviceUsed
				? deviceUsed.deviceName +
				  ' ' +
				  deviceUsed.model +
				  ' (' +
				  deviceUsed.deviceUniqueId +
				  ')'
				: '';
			let newRow = worksheet.addRow(
				{
					...contact,
					...fieldData,
					scannedBy: lead.i_.created?.by
						? userHelper.get(lead.i_.created?.by)?.name
						: '',
					scanningDevice: deviceInfo,
					dateScanned: moment(lead.i_.created?.dt).format('YYYY-MM-DD hh:mm'),
					notes:
						noteHelper.allByLead(lead)?.length > 0
							? noteHelper.allByLead(lead).map((note: NoteEntity) => {
									return note.value;
							  })
							: ''
				},
				''
			);

			let leadResponses = responseHelper.allByLead(lead);
			allQualifiers.forEach((qualifier, index) => {
				let cell = newRow.getCell(mainColCount + 1 + index);
				let qualifierValueIds: string[] = leadResponses
					.filter(
						response =>
							response.qualifierId === qualifier.id && response.value === 'true'
					)
					.map(value => value.qualifierValueId ?? '');
				let qualifierValues = qualifierValueHelper
					.gets(qualifierValueIds)
					.filter(isObjectStatusActive);

				if (cell && qualifierValues) {
					let columnValue = '';
					qualifierValues.forEach((qualifierValue, index) => {
						columnValue =
							(columnValue === '' ? columnValue : columnValue + ',') +
							qualifierValue.name;
					});
					cell.value = columnValue;
				}
			});

			let notesVlaues = '';
			noteHelper.allByLead(lead).forEach((note, index) => {
				notesVlaues =
					(notesVlaues === '' ? notesVlaues : notesVlaues + ',') + note.value;
			});
			let notesCell = newRow.getCell(mainColCount);
			if (notesCell) {
				notesCell.value = notesVlaues;
			}
		});

		const buffer = await workbook.xlsx.writeBuffer();
		const fileType =
			'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
		const fileExtension = '.xlsx';

		const blob = new Blob([buffer], { type: fileType });

		saveAs(
			blob,
			(activeContext.code || '') +
				'Leads-' +
				moment().format('YYYY-MM-DD-hmm') +
				fileExtension
		);

		setWorkingStatus(undefined);
	};

	return (
		<>
			{/*<IonPopover
				event={leadScan.event}
				isOpen={leadScan.showPopover}
				onDidDismiss={() =>
					setLeadScan({ showPopover: false, event: undefined })
				}
			>
				<LeadScan></LeadScan>
			</IonPopover>
			<IonButton
				onClick={(e: any) => {
					e.persist();
					setLeadScan({ showPopover: true, event: e });
				}}
			>
				<IonIcon icon={addOutline} style={{ paddingRight: '15px' }} />
				<IonLabel>Add Lead</IonLabel>
			</IonButton>
			<LeadScan></LeadScan>
			*/}

			{loading ? (
				<IonLabel class="ion-text-center">
					<br />
					<h1>
						<IonIcon icon={peopleOutline} size="large" />
					</h1>
					<h2>Loading Leads...</h2>
				</IonLabel>
			) : allLeads.length <= 0 ? (
				<IonLabel class="ion-text-center">
					<br />
					<h1>
						<IonIcon icon={peopleOutline} size="large" />
					</h1>
					<h2>No leads found.</h2>
					<p>You have not collected any leads.</p>
				</IonLabel>
			) : (
				<IonGrid>
					<IonRow>
						<IonCol>
							<SearchBar onChange={onSerach} />
							{workingStatus && (
								<IonLoading
									isOpen={true}
									message={`<h2>Working</h2>${workingStatus}`}
								/>
							)}
						</IonCol>
					</IonRow>
					<IonRow>
						<IonCol>
							<IonToolbar>
								<IonGrid>
									<IonRow>
										<IonCol sizeXs="12" sizeSm="2" size="4" sizeLg="6">
											<Selector
												key="SortBy"
												name="SortBy"
												items={sortItems}
												value={sort.value}
												onSelect={onSortValueChange}
												icon={calendarOutline}
												iconOnly={false}
												noItemsText="No options"
											></Selector>
										</IonCol>
										<IonCol
											sizeXs="12"
											sizeSm="10"
											size="8"
											sizeLg="6"
											class="ion-text-end"
										>
											<IonGrid>
												<IonRow>
													<IonCol sizeXs="2" size="1" class="ion-text-end">
														<IonButton
															hidden={false}
															size="small"
															color="success"
															onClick={onExportLeads}
														>
															<IonIcon icon={downloadOutline}></IonIcon>
														</IonButton>
													</IonCol>
													<IonCol sizeXs="10" size="11" class="ion-text-end">
														<ListPaging
															onPageChange={onPageChange}
															onPageSizeChanged={onPageSizeChanged}
															pageSelected={pageSelected}
															pageSize={pageSize}
															totalItems={totalLeads}
														/>
													</IonCol>
												</IonRow>
											</IonGrid>
										</IonCol>
									</IonRow>
								</IonGrid>
							</IonToolbar>
							<IonList>{leadListItems}</IonList>
							<IonToolbar>
								<IonGrid>
									<IonRow>
										<IonCol size="5"></IonCol>
										<IonCol size="7">
											<ListPaging
												onPageChange={onPageChange}
												onPageSizeChanged={onPageSizeChanged}
												pageSelected={pageSelected}
												pageSize={pageSize}
												totalItems={totalLeads}
											/>
										</IonCol>
									</IonRow>
								</IonGrid>
							</IonToolbar>
						</IonCol>
					</IonRow>
				</IonGrid>
			)}
		</>
	);
};
