import React, {
	useEffect,
	useState,
	useRef,
	Component,
	RefObject
} from 'react';

//DATA
import {
	ContextEntities,
	UserEntities,
	UserEntity,
	isObjectStatusActive,
	LicenseEntities,
	OrderEntity,
	LicenseEntity,
	LicenseType,
	LicenseServiceType,
	ObjectType,
	ObjectIds
} from '../../store';
import { useCtx } from '../../../config/hooks';
import { newEntity } from '../../../app/utils';
import uuidV4 from 'uuid/v4';

import moment from 'moment';
import {
	IonCard,
	IonCardHeader,
	IonCardTitle,
	IonCardContent,
	IonList,
	IonToolbar,
	IonButtons,
	IonItem,
	IonInput,
	IonLabel,
	IonGrid,
	IonRow,
	IonCol,
	IonBackButton,
	IonButton,
	IonSegment,
	IonSegmentButton,
	IonTitle,
	IonContent,
	IonLoading,
	IonAlert,
	IonModal,
	IonSelect,
	IonSelectOption,
	IonIcon
} from '@ionic/react';
import { Avatar } from '../../../app/ui/components';
import { useToast } from '@agney/ir-toast';
import { LeadList } from '../containers/Lead';
import { LicenseCardList } from '../containers/License';
import AdminSignoutSheetView from './AdminSignoutSheetView';
import AdminQuestionsSheetView from './AdminQuestionsSheetView';
import AdminPrintLicensesView from './AdminPrintLicensesView';
import { closeCircle, pencilOutline } from 'ionicons/icons';

const ServiceView: React.FC = () => {
	const ctx = useCtx<{}>({});
	const {
		config: [config],
		app: { activeUser },
		lead: {
			serviceHelper,
			activeService,
			contextHelper,
			activeContext,
			activeEvent,
			userHelper,
			contactHelper,
			licenseHelper,
			orderHelper
		}
	} = ctx;

	const [currentAccountTab, setCurrentAccountTab] = useState('Leads');
	const [sendEmail, setSendEmail] = useState<
		{ emailType: string; email: string; alertTitle: string } | undefined
	>(undefined);
	const [workingStatus, setWorkingStatus] = useState<string | undefined>(
		undefined
	);
	const [showAddEditServiceContact, setShowAddEditServiceContact] = useState(
		false
	);
	const [showAddEditLicense, setShowAddEditLicense] = useState(false);
	const [showDeleteUserAlert, setShowDeleteUserAlert] = useState('');
	const [addEditLicenseForm, setAddEditLicenseForm] = useState<{
		serviceType: LicenseServiceType;
		quantity: number;
	}>({ serviceType: LicenseServiceType.LeadApp, quantity: 0 });

	const adminSignoutSheetViewRef = useRef<HTMLDivElement>(null);
	const adminQuestionsSheetViewRef = useRef<HTMLDivElement>(null);
	const adminPrintLicensesViewRef = useRef<HTMLDivElement>(null);

	const Toast = useToast();

	const handlePrint = (componentRef: RefObject<HTMLDivElement>) => {
		var divContents = componentRef.current?.innerHTML;
		var a = window.open('', '', 'height=800, width=800');
		if (divContents && a) {
			a.document.write('<html>');
			a.document.write('<body>');
			a.document.write(divContents);
			a.document.write('</body></html>');
			a.document.close();
			setTimeout(function () {
				a?.print();
			}, 1000);
		}
	};

	if (!activeService || !activeEvent || !activeContext) {
		return <IonLabel>No Service found</IonLabel>;
	}

	let contexts: ContextEntities = activeEvent
		? contextHelper.allByEventId(activeEvent.id)
		: [];
	let licenses: LicenseEntities = licenseHelper.allByContexts(contexts, [
		activeService
	]);

	const users: UserEntities = userHelper
		.gets(activeService?.userIds || [''])
		.filter(isObjectStatusActive);

	const mainContact = contactHelper.get_Organization(
		activeService?.contactIds ? activeService?.contactIds[0] : ''
	);

	const updateActiveService = () => {
		if (activeService) {
			serviceHelper.write(ctx, {
				userId: activeUser?.userId,
				services: [activeService]
			});
			Toast.success('Account updated successfully!');
		}
	};

	const updateMainContact = () => {
		if (mainContact) {
			contactHelper.write(ctx, {
				userId: activeUser?.userId,
				contacts: [mainContact]
			});
			Toast.success('Account updated successfully!');
		}
	};

	const allOrders = orderHelper
		.all()
		.filter(isObjectStatusActive)
		.filter(
			o =>
				o.contextId === activeContext?.id &&
				o.contactIds?.includes(mainContact?.id || '')
		);

	const loadOrders = () => {
		// ctx.lead.service.readActiveOrderData(ctx, () => {
		// 	console.log('Orders loaded');
		// });
	};

	const sortActiveOrders = (a: OrderEntity, b: OrderEntity) => {
		let aContacts = contactHelper.gets(a.contactIds || []);
		let bContacts = contactHelper.gets(b.contactIds || []);
		//if (aContacts && aContacts.length && bContacts && bContacts.length) {
		let aMainContact = aContacts[0];
		let bMainContact = bContacts[0];
		return (aMainContact?.name || '') > (bMainContact?.name || '')
			? 1
			: (bMainContact?.name || '') > (aMainContact?.name || '')
			? -1
			: 0;
		//}
		//return true;
	};
	const eventOrdersListItems = allOrders
		.sort(sortActiveOrders)
		.map((order: OrderEntity) => {
			let contacts = contactHelper.gets(order.contactIds || []);
			let licenses = licenseHelper.allByOrderId(order.id);
			let mainContact = contacts[0];
			return (
				<IonItem
					key={order.id}
					button={true}
					routerLink="/admin/order"
					onClick={() => {
						orderHelper.set(order.id);
					}}
				>
					<IonGrid>
						<IonRow>
							<IonCol>{order.code}</IonCol>
							<IonCol>{mainContact?.name}</IonCol>
							<IonCol>
								{licenses.map((license: LicenseEntity) => {
									return (
										<div>
											{licenseHelper.licenseTypeToString(license.serviceType)}:{' '}
											{license.quantity}
										</div>
									);
								})}
							</IonCol>
							<IonCol>
								{moment(order.i_.created.dt)
									.local()
									.format('YYYY-MM-DD hh:mm A')}
							</IonCol>
						</IonRow>
					</IonGrid>
				</IonItem>
			);
		});

	const userListItems = users.map((user: UserEntity) => {
		return (
			<IonItem
				key={user.id}
				button={true}
				routerLink="/lead-service/user"
				onClick={e => {
					e.stopPropagation();
					userHelper.set(user.id);
				}}
			>
				<IonGrid>
					<IonRow>
						<IonCol>
							<IonLabel>
								<div>
									<div
										style={{ display: 'inline-block', paddingRight: '20px' }}
									>
										<Avatar name={user.name} />
									</div>
									<div style={{ display: 'inline-block' }}>
										<h2>
											{user.firstName || user.lastName
												? user.firstName + ' ' + user.lastName
												: ''}
										</h2>
										<h2>{user.email}</h2>
									</div>
								</div>
							</IonLabel>
						</IonCol>
						<IonCol>
							<IonButton
								hidden={!activeUser?.security.includes('administrator')}
								size="small"
								color="danger"
								onClick={e => {
									e.stopPropagation();
									e.preventDefault();
									setShowDeleteUserAlert(user.id);
								}}
							>
								Remove Access
							</IonButton>
						</IonCol>
					</IonRow>
				</IonGrid>
			</IonItem>
		);
	});

	const onSuccessSendEmail = (result: any) => {
		setSendEmail(undefined);
		setWorkingStatus(undefined);
		Toast.success('Email successfully sent.');
	};
	const onError = (message: string) => {
		Toast.error(message);
		setWorkingStatus(undefined);
	};
	const sendLoginEmail = (email: string, to: string) => {
		setWorkingStatus('Sending...');
		serviceHelper.sendServiceLoginEmail(
			ctx,
			activeService,
			activeContext || {},
			licenses,
			to,
			undefined,
			'order@conexsys.com',
			onError,
			onSuccessSendEmail
		);
	};
	const sendLicenseEmail = (email: string, to: string) => {
		setWorkingStatus('Sending...');
		let le: LicenseEntities = licenses.filter(
			l => l.serviceType === LicenseServiceType.LeadApp
		);
		serviceHelper.sendMobileLicenseEmail(
			ctx,
			activeService,
			activeContext || {},
			le,
			to,
			undefined,
			'order@conexsys.com',
			onError,
			onSuccessSendEmail
		);
	};
	const sendPostShowEmail = (email: string, to: string) => {
		setWorkingStatus('Sending...');
		if (mainContact) {
			serviceHelper.sendPostShowEmail(
				ctx,
				activeService,
				activeContext,
				mainContact,
				to,
				undefined,
				'order@conexsys.com',
				onError,
				onSuccessSendEmail
			);
		}
	};

	const AddLicense = (type: LicenseServiceType, quantity: number) => {
		if (type && quantity > 0 && activeContext && activeService && activeUser) {
			let userIds: ObjectIds = [];
			licenses.forEach(l => {
				if ((l.userIds && l.userIds?.length) || 0 > 0) {
					userIds.concat(l.userIds || []);
				}
			});
			if (activeUser) userIds.push(activeUser?.id);

			let license: LicenseEntity = {
				...newEntity(ctx, ObjectType.License),
				type: LicenseType.Device,
				serviceType: type,
				contextId: activeContext?.id || '',
				serviceId: activeService?.id || '',
				userIds: userIds,
				code: uuidV4(),
				name: licenseHelper.licenseTypeToString(type) + ' License',
				serviceName: licenseHelper.licenseTypeToString(type) + ' License',
				quantity: quantity,
				uses: 0
			};

			if (license) {
				// write new/update licenses
				licenseHelper.write(ctx, { licenses: [license] });
				setShowAddEditLicense(false);
				setAddEditLicenseForm({
					serviceType: LicenseServiceType.LeadApp,
					quantity: 0
				});
			}
		}
	};

	const removeUserAccess = (userId: string) => {
		if (userId) {
			let idx = activeService.userIds?.indexOf(userId);
			if (activeService.userIds && idx !== undefined && idx >= 0) {
				activeService.userIds.splice(idx, 1);
				serviceHelper.write(ctx, {
					userId: activeUser?.userId,
					services: [activeService]
				});

				licenses.forEach(l => {
					let idx = l.userIds?.indexOf(userId);
					if (l.userIds && idx !== undefined && idx >= 0) {
						l.userIds.splice(idx, 1);
					}
				});
				licenseHelper.write(ctx, {
					userId: activeUser?.userId,
					licenses: licenses
				});

				Toast.success('User Access Removed successfully!');
			}
		}
	};

	return (
		<IonContent>
			<IonAlert
				isOpen={showDeleteUserAlert !== ''}
				onDidDismiss={() => {
					setShowDeleteUserAlert('');
				}}
				header={'Deactivate License?'}
				message={
					'Deactivating a license will <strong>remove</strong> this users access to this service.'
				}
				buttons={[
					{
						text: 'Cancel',
						role: 'cancel',
						cssClass: 'light',
						handler: () => {
							setShowDeleteUserAlert('');
						}
					},
					{
						text: 'Deactivate',
						cssClass: 'light',
						handler: () => {
							removeUserAccess(showDeleteUserAlert);
							setShowDeleteUserAlert('');
						}
					}
				]}
			></IonAlert>
			<IonModal
				isOpen={showAddEditLicense}
				backdropDismiss={false}
				onDidDismiss={() => {
					setShowAddEditLicense(false);
				}}
			>
				<IonContent>
					<IonList>
						<IonItem>
							<IonLabel>License Type</IonLabel>
							<IonSelect
								value={addEditLicenseForm.serviceType}
								onIonChange={e => {
									setAddEditLicenseForm({
										...addEditLicenseForm,
										serviceType: e.detail.value as LicenseServiceType
									});
								}}
							>
								<IonSelectOption value={LicenseServiceType.LeadApp}>
									Mobile App
								</IonSelectOption>
								<IonSelectOption value={LicenseServiceType.Opticon}>
									Opticon
								</IonSelectOption>
								<IonSelectOption value={LicenseServiceType.Sst}>
									SST
								</IonSelectOption>
								<IonSelectOption value={LicenseServiceType.LeadApi}>
									API
								</IonSelectOption>
							</IonSelect>
						</IonItem>
						<IonItem>
							<IonLabel>Quantity</IonLabel>
							<IonInput
								autocomplete="off"
								defaultValue="0"
								min={'0'}
								max={'30'}
								type="number"
								value={addEditLicenseForm.quantity}
								onIonChange={e => {
									setAddEditLicenseForm({
										...addEditLicenseForm,
										quantity: Number(e.detail.value)
									});
								}}
							></IonInput>
						</IonItem>
					</IonList>
				</IonContent>
				<IonButton
					onClick={() =>
						AddLicense(
							addEditLicenseForm.serviceType,
							addEditLicenseForm.quantity
						)
					}
				>
					Save
				</IonButton>
				<IonButton onClick={() => setShowAddEditLicense(false)}>
					Close
				</IonButton>
			</IonModal>
			<IonModal
				isOpen={showAddEditServiceContact}
				backdropDismiss={false}
				onDidDismiss={() => {
					setShowAddEditServiceContact(false);
				}}
			>
				<IonToolbar>
					<IonToolbar>
						<IonTitle>Edit Contact Information</IonTitle>
						<IonButtons slot="end">
							<IonButton
								fill="clear"
								onClick={() => setShowAddEditServiceContact(false)}
							>
								<IonIcon src={closeCircle}></IonIcon>
							</IonButton>
						</IonButtons>
					</IonToolbar>
				</IonToolbar>
				<IonContent>
					<IonList>
						<IonItem>
							<IonGrid>
								<IonRow>
									<IonCol size="3">
										<IonLabel>Company</IonLabel>
									</IonCol>
									<IonCol>
										<IonInput
											placeholder="Enter Company Name"
											value={activeService?.name}
											onIonBlur={(e: any) => {
												if (
													activeService &&
													e.target &&
													e.target?.value.trim() !== '' &&
													e.target?.value.trim() !== activeService?.name?.trim()
												) {
													activeService.name = e.target?.value.trim();
													updateActiveService();
												}
											}}
										></IonInput>
									</IonCol>
								</IonRow>
							</IonGrid>
						</IonItem>
						<IonItem>
							<IonGrid>
								<IonRow>
									<IonCol size="3">
										<IonLabel>Address</IonLabel>
									</IonCol>
									<IonCol>
										<IonInput
											placeholder="Enter Address 1"
											value={mainContact?.address1}
											onIonBlur={(e: any) => {
												if (
													mainContact &&
													e.target &&
													e.target?.value.trim() !== '' &&
													e.target?.value.trim() !==
														mainContact?.address1?.trim()
												) {
													mainContact.address1 = e.target?.value.trim();
													updateMainContact();
												}
											}}
										></IonInput>
									</IonCol>
								</IonRow>
								<IonRow>
									<IonCol size="3"></IonCol>
									<IonCol>
										<IonInput
											placeholder="Enter Address 2"
											value={mainContact?.address2}
											onIonBlur={(e: any) => {
												if (
													mainContact &&
													e.target &&
													e.target?.value.trim() !== '' &&
													e.target?.value.trim() !==
														mainContact?.address2?.trim()
												) {
													mainContact.address2 = e.target?.value.trim();
													updateMainContact();
												}
											}}
										></IonInput>
									</IonCol>
								</IonRow>
							</IonGrid>
						</IonItem>
						<IonItem>
							<IonGrid>
								<IonRow>
									<IonCol size="3">
										<IonLabel>City</IonLabel>
									</IonCol>
									<IonCol>
										<IonInput
											placeholder="Enter City"
											value={mainContact?.city}
											onIonBlur={(e: any) => {
												if (
													mainContact &&
													e.target &&
													e.target?.value.trim() !== '' &&
													e.target?.value.trim() !== mainContact?.city?.trim()
												) {
													mainContact.city = e.target?.value.trim();
													updateMainContact();
												}
											}}
										></IonInput>
									</IonCol>
								</IonRow>
							</IonGrid>
						</IonItem>
						<IonItem>
							<IonGrid>
								<IonRow>
									<IonCol size="3">
										<IonLabel>First Name</IonLabel>
									</IonCol>
									<IonCol>
										<IonInput
											placeholder="Enter First Name"
											value={mainContact?.contactFirstName}
											onIonBlur={(e: any) => {
												if (
													mainContact &&
													e.target &&
													e.target?.value.trim() !== '' &&
													e.target?.value.trim() !==
														mainContact?.contactFirstName?.trim()
												) {
													mainContact.contactFirstName = e.target?.value.trim();
													updateMainContact();
												}
											}}
										></IonInput>
									</IonCol>
								</IonRow>
							</IonGrid>
						</IonItem>
						<IonItem>
							<IonGrid>
								<IonRow>
									<IonCol size="3">
										<IonLabel>Last Name</IonLabel>
									</IonCol>
									<IonCol>
										<IonInput
											placeholder="Enter Last Name"
											value={mainContact?.contactLastName}
											onIonBlur={(e: any) => {
												if (
													mainContact &&
													e.target &&
													e.target?.value.trim() !== '' &&
													e.target?.value.trim() !==
														mainContact?.contactLastName?.trim()
												) {
													mainContact.contactLastName = e.target?.value.trim();
													updateMainContact();
												}
											}}
										></IonInput>
									</IonCol>
								</IonRow>
							</IonGrid>
						</IonItem>
						<IonItem>
							<IonGrid>
								<IonRow>
									<IonCol size="3">
										<IonLabel>Email</IonLabel>
									</IonCol>
									<IonCol>
										<IonInput
											type="email"
											placeholder="Enter Email"
											value={mainContact?.contactEmail}
											onIonBlur={(e: any) => {
												if (
													mainContact &&
													e.target &&
													e.target?.value.trim() !== '' &&
													e.target?.value.trim() !==
														mainContact?.contactEmail?.trim()
												) {
													mainContact.contactEmail = e.target?.value.trim();
													updateMainContact();
												}
											}}
										></IonInput>
									</IonCol>
								</IonRow>
							</IonGrid>
						</IonItem>
						<IonItem>
							<IonGrid>
								<IonRow>
									<IonCol size="3">
										<IonLabel>Phone Number</IonLabel>
									</IonCol>
									<IonCol>
										<IonInput
											type="tel"
											placeholder="Enter Phone Number"
											value={mainContact?.contactPhone}
											onIonBlur={(e: any) => {
												if (
													mainContact &&
													e.target &&
													e.target?.value.trim() !== '' &&
													e.target?.value.trim() !==
														mainContact?.contactPhone?.trim()
												) {
													mainContact.contactPhone = e.target?.value.trim();
													updateMainContact();
												}
											}}
										></IonInput>
									</IonCol>
								</IonRow>
							</IonGrid>
						</IonItem>
					</IonList>
				</IonContent>
			</IonModal>
			<div
				hidden={true}
				key="AdminSignoutSheetPrintDiv"
				ref={adminSignoutSheetViewRef}
			>
				<AdminSignoutSheetView />
			</div>
			<div
				hidden={true}
				key="AdminQuestionsSheetDiv"
				ref={adminQuestionsSheetViewRef}
			>
				<AdminQuestionsSheetView />
			</div>
			<div
				hidden={true}
				key="AdminPrintLicensesViewDiv"
				ref={adminPrintLicensesViewRef}
			>
				<AdminPrintLicensesView servicesToPrint={[activeService.id]} />
			</div>

			<IonToolbar>
				<IonButtons slot="start">
					<IonBackButton text="Back" defaultHref="/admin/services" />
				</IonButtons>
				<IonTitle>Admin: Account</IonTitle>
			</IonToolbar>
			<IonGrid>
				<IonRow>
					<IonCol>
						<IonCard>
							<IonCardHeader>
								<IonCardTitle>
									{activeService?.name}
									<IonButton
										class="ion-float-right"
										color="light"
										size="small"
										onClick={event => {
											event.stopPropagation();
											setShowAddEditServiceContact(true);
										}}
									>
										<IonIcon src={pencilOutline} />
									</IonButton>
								</IonCardTitle>
							</IonCardHeader>
							<IonCardContent hidden={!mainContact}>
								{mainContact?.address1} {mainContact?.address2}
								<br />
								{mainContact?.city} {mainContact?.subdivision}{' '}
								{mainContact?.postal}
								<br />
								{mainContact?.contactEmail}
								<br />
							</IonCardContent>
						</IonCard>
					</IonCol>
					<IonCol>
						<IonCard>
							<IonCardHeader>
								<IonCardTitle>
									<IonItem>Account Actions</IonItem>
								</IonCardTitle>
								<IonCardContent>
									<IonButton
										onClick={() => {
											handlePrint(adminSignoutSheetViewRef);
										}}
									>
										Print Signout Sheet
									</IonButton>
									<IonButton
										onClick={() => {
											handlePrint(adminQuestionsSheetViewRef);
										}}
									>
										Print Qualifiers Sheet
									</IonButton>
									<IonButton routerLink="/lead-service/leads">
										View Account
									</IonButton>
									<IonButton
										onClick={() => {
											setSendEmail({
												emailType: 'accountLogin',
												email: mainContact?.contactEmail ?? '',
												alertTitle: `Send Login Email`
											});
										}}
									>
										Send Login Email
									</IonButton>
									<IonButton
										onClick={() => {
											setSendEmail({
												emailType: 'postShow',
												email: mainContact?.contactEmail ?? '',
												alertTitle: `Send Post Show Email`
											});
										}}
									>
										Send Post Show Email
									</IonButton>
								</IonCardContent>
							</IonCardHeader>
						</IonCard>
					</IonCol>
				</IonRow>
				<IonRow>
					<IonCol>
						<IonCard>
							<IonCardContent>
								<IonSegment
									value={currentAccountTab}
									onIonChange={e =>
										setCurrentAccountTab(e.detail.value || 'Leads')
									}
								>
									<IonSegmentButton value="Leads">Leads</IonSegmentButton>
									<IonSegmentButton value="Orders" onClick={() => loadOrders()}>
										Orders
									</IonSegmentButton>
									<IonSegmentButton value="Licenses">Licenses</IonSegmentButton>
									<IonSegmentButton value="Downloads" hidden={true}>
										Downloads
									</IonSegmentButton>
									<IonSegmentButton value="Users">Users</IonSegmentButton>
								</IonSegment>
								<IonList hidden={currentAccountTab !== 'Leads'}>
									<LeadList service={activeService}></LeadList>
								</IonList>
								<IonList hidden={currentAccountTab !== 'Orders'}>
									{eventOrdersListItems}
								</IonList>
								<IonGrid hidden={currentAccountTab !== 'Licenses'}>
									<IonRow>
										<IonCol>
											<IonButton onClick={() => setShowAddEditLicense(true)}>
												Add License
											</IonButton>
											<IonButton
												hidden={
													licenses.filter(
														l => l.serviceType === LicenseServiceType.LeadApp
													).length <= 0
												}
												onClick={() => {
													handlePrint(adminPrintLicensesViewRef);
												}}
											>
												Print Mobile App Licenses
											</IonButton>
										</IonCol>
									</IonRow>
									<IonRow>
										<IonCol>
											<IonList>
												<LicenseCardList
													title="Mobile App License(s)"
													licenseType="lead-app"
													service={activeService}
													event={activeEvent}
													licenses={licenses}
												/>
												<LicenseCardList
													title="Lead API License(s)"
													licenseType="lead-api"
													service={activeService}
													event={activeEvent}
													licenses={licenses}
												/>
												<LicenseCardList
													title="SST Device License(s)"
													licenseType="sst"
													service={activeService}
													event={activeEvent}
													licenses={licenses}
												/>
												<LicenseCardList
													title="Opticon License(s)"
													licenseType="opticon"
													service={activeService}
													event={activeEvent}
													licenses={licenses}
												/>
											</IonList>
										</IonCol>
									</IonRow>
								</IonGrid>

								<IonList hidden={currentAccountTab !== 'Downloads'}>
									Downloads
								</IonList>
								<IonList hidden={currentAccountTab !== 'Users'}>
									{userListItems}
								</IonList>
							</IonCardContent>
						</IonCard>
					</IonCol>
				</IonRow>
			</IonGrid>
			{sendEmail && (
				<IonAlert
					isOpen={true}
					onDidDismiss={() => setSendEmail(undefined)}
					header={sendEmail?.alertTitle}
					inputs={[
						{
							name: 'email',
							type: 'email',
							label: 'Email',
							value: sendEmail.email
						}
					]}
					buttons={[
						{
							text: 'Cancel',
							role: 'cancel',
							cssClass: 'light'
						},
						{
							text: 'Send',
							handler: v => {
								if (sendEmail.emailType === 'accountLogin') {
									sendLoginEmail(sendEmail.email || '', v.email);
								} else if (sendEmail.emailType === 'license') {
									sendLicenseEmail(sendEmail.email || '', v.email);
								} else if (sendEmail.emailType === 'postShow') {
									sendPostShowEmail(sendEmail.email || '', v.email);
								}
							}
						}
					]}
				/>
			)}
			{workingStatus && (
				<IonLoading
					isOpen={true}
					message={`<h2>${workingStatus}</h2>`}
					duration={10000}
				/>
			)}
		</IonContent>
	);
};

export default ServiceView;
