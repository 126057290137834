import React, { useEffect, useState } from 'react';

//Data
import {
	EventEntities,
	ContextEntities,
	ContextEntity,
	ContextEntityFilter
} from '../../store';
import { useCtx } from '../../../config/hooks';

//UI
import {
	IonGrid,
	IonRow,
	IonCol,
	IonModal,
	IonSearchbar,
	IonToolbar,
	IonTitle,
	IonIcon,
	IonButton,
	IonButtons,
	IonContent,
	IonCard,
	IonCardContent,
	IonCardHeader,
	IonCardTitle,
	IonSegment,
	IonSegmentButton
} from '@ionic/react';
import { closeCircle, addCircle, settingsOutline } from 'ionicons/icons';
import moment from 'moment';
import { AddContextFromEvent } from '../containers/Context';

const AdminEventsView: React.FC = () => {
	const ctx = useCtx<{}>({});
	const {
		lead: { eventHelper, contextHelper, activeContext }
	} = ctx;
	const [contextFilter, setContextFilter] = useState<ContextEntityFilter>({
		keyWords: ''
	});
	const [showModal, setShowModal] = useState(false);
	const [currentTab, setCurrentTab] = useState('upcoming');

	useEffect(() => {
		setCurrentTab('upcoming');
		changeSelectedTab('upcoming');
		setContextFilter(prevFilter => ({
			...prevFilter,
			keyWords: ''
		}));
		eventHelper.readAll(ctx, {});
		contextHelper.readAll(ctx, {});
	}, []);
	useEffect(() => {
		//setCurrentTab('upcoming');
		//changeSelectedTab('upcoming');
		//setContextFilter(prevFilter => ({
		//	...prevFilter,
		//	keyWords: ''
		//}));
	}, [activeContext]);

	const eventContexts: ContextEntities = contextHelper.allByFilter(
		ctx,
		contextFilter
	);
	const sortContexts = (a: ContextEntity, b: ContextEntity) => {
		let aEvents: EventEntities = eventHelper.gets(a.eventIds);
		let bEvents: EventEntities = eventHelper.gets(b.eventIds);
		let aE = aEvents[0];
		let bE = bEvents[0];
		if (aE && bE) {
			if (currentTab === 'upcoming') {
				return moment(aE.start || '').toDate() > moment(bE.start || '').toDate()
					? 1
					: moment(bE.start || '').toDate() > moment(aE.start || '').toDate()
					? -1
					: 0;
			} else {
				return moment(aE.start || '').toDate() < moment(bE.start || '').toDate()
					? 1
					: moment(bE.start || '').toDate() < moment(aE.start || '').toDate()
					? -1
					: 0;
			}
		} else {
			return 1;
		}
	};

	const changeSelectedTab = (n?: string) => {
		setCurrentTab(n || 'upcoming');
		let fromDate: Date | undefined,
			toDate: Date | undefined = undefined;
		if (n === 'upcoming') {
			fromDate = moment.utc().toDate();
		} else if (n === 'past') {
			toDate = moment.utc().toDate();
		}

		setContextFilter(prevFilter => ({
			...prevFilter,
			fromDate: fromDate,
			toDate: toDate
		}));
	};

	const onSerach = (value: string | undefined) => {
		setContextFilter(prevFilter => ({
			...prevFilter,
			keyWords: value ? value : ''
		}));
	};

	const eventContextListItems = eventContexts
		.sort(sortContexts)
		.map((context: ContextEntity) => {
			let events: EventEntities = eventHelper.gets(context.eventIds);
			let mainEvent = events[0];
			return (
				<IonCol
					key={'eventCardCol_' + context.id}
					size="12"
					sizeSm="6"
					sizeLg="4"
				>
					<IonCard
						key={'eventCard_' + context.id}
						button={true}
						routerLink="/admin/services"
						onClick={event => {
							event.stopPropagation();
							eventHelper.set(context.eventIds[0] || undefined);
							contextHelper.set(context.id);
						}}
						color={activeContext?.id === context.id ? 'light' : undefined}
						style={{ maxWidth: '400px' }}
					>
						<div
							style={{
								height: '120px',
								width: '100%',
								backgroundColor: 'rgb(47, 64, 80)',
								backgroundImage:
									'url("' + mainEvent?.header?.OriginalFilePath || '' + '")',
								backgroundSize: '100% 100%',
								backgroundRepeat: 'no-repeat'
							}}
						>
							<IonButton
								class="ion-float-right"
								routerLink="/admin/event"
								color="light"
								size="small"
								onClick={event => {
									event.stopPropagation();
									contextHelper.set(context.id);
									eventHelper.set(context.eventIds[0] || undefined);
								}}
							>
								<IonIcon src={settingsOutline} />
							</IonButton>
						</div>
						<IonCardHeader>
							<IonCardTitle>
								{context.name} ({context.code})
							</IonCardTitle>
						</IonCardHeader>
						<IonCardContent>
							<IonGrid>
								<IonRow>
									<IonCol>
										<strong>Dates:</strong>{' '}
										{moment
											.utc(mainEvent?.start)
											.local()
											.format('dddd MMMM Do, YYYY')}{' '}
										<br />
										<strong>Location:</strong> {mainEvent?.location}
									</IonCol>
								</IonRow>
								<IonRow>
									<IonCol>
										<IonButton
											class="ion-float-right"
											routerLink="/admin/services"
											color="light"
											onClick={event => {
												event.stopPropagation();
												contextHelper.set(context.id);
												eventHelper.set(context.eventIds[0] || undefined);
											}}
										>
											View
										</IonButton>
									</IonCol>
								</IonRow>
							</IonGrid>
						</IonCardContent>
					</IonCard>
				</IonCol>
			);
		});

	return (
		<IonContent>
			<IonModal isOpen={showModal}>
				<IonToolbar>
					<IonToolbar>
						<IonTitle>Create New Lead Event</IonTitle>
						<IonButtons slot="end">
							<IonButton fill="clear" onClick={() => setShowModal(false)}>
								<IonIcon src={closeCircle}></IonIcon>
							</IonButton>
						</IonButtons>
					</IonToolbar>
				</IonToolbar>
				<IonContent>
					<AddContextFromEvent
						onCompleted={() => setShowModal(false)}
					></AddContextFromEvent>
				</IonContent>
			</IonModal>
			<IonToolbar>
				<IonTitle>Admin: Lead Events</IonTitle>
				<IonButton
					className="ion-float-right"
					onClick={() => setShowModal(true)}
				>
					<IonIcon src={addCircle} />
					New
				</IonButton>
				<IonSearchbar
					value={contextFilter.keyWords}
					onIonChange={e => onSerach(e.detail.value)}
				></IonSearchbar>
				<IonSegment
					value={currentTab}
					onIonChange={e => {
						changeSelectedTab(e.detail.value);
					}}
				>
					<IonSegmentButton value="upcoming">Upcoming</IonSegmentButton>
					<IonSegmentButton value="past">Past Events</IonSegmentButton>
					<IonSegmentButton value="all">All Events</IonSegmentButton>
				</IonSegment>
			</IonToolbar>
			<IonGrid>
				<IonRow>{eventContextListItems}</IonRow>
			</IonGrid>
		</IonContent>
	);
};

export default AdminEventsView;
