import React, { useState } from 'react';
import {
	IonCard,
	IonCardHeader,
	IonCardTitle,
	IonCardContent,
	IonItem,
	IonLabel,
	IonText
} from '@ionic/react';

import { LeadEntity} from '../../../store';
import _ from 'lodash';
import { useCtx } from '../../../../config/hooks';
import moment from 'moment';

export interface LeadScanDetailsConfigProps {
	lead: LeadEntity;
}

export const LeadScanDetails: React.FC<LeadScanDetailsConfigProps> = props => {
	const {
		config: [config],
		lead: { deviceHelper, userHelper }
	} = useCtx<LeadScanDetailsConfigProps>({});


	const LeadScanDate: string = moment(props.lead.i_.created?.dt).format('YYYY-MM-DD hh:mm');
	const LeadScannedBy = props.lead.i_.created?.by ? userHelper.get(props.lead.i_.created?.by)?.name : '';
	const deviceUsed = deviceHelper.get(props.lead.i_.created?.on ?? '');

	return (
		<>
			<IonCard>
				<IonCardHeader>
					<IonCardTitle>Scan Details</IonCardTitle>
				</IonCardHeader>
				<IonCardContent>
					<IonItem><IonLabel>Date:</IonLabel><IonText>{LeadScanDate}</IonText></IonItem>
					<IonItem><IonLabel>User:</IonLabel><IonText>{LeadScannedBy}</IonText></IonItem>
					<IonItem><IonLabel>Device:</IonLabel><IonText> {deviceUsed ? deviceUsed.deviceName + " " + deviceUsed.model : ''}<br />{deviceUsed ? "(" + deviceUsed.deviceUniqueId + ")" : ''}</IonText></IonItem>
				</IonCardContent>
			</IonCard>
		</>
	);
};
