import { useSelector, useDispatch } from 'react-redux';
import EntityHelper from '../../../storage/classes/Entity';
import {
	IEntityHelper,
	EntityHelperOpts,
	entityHelperDefaultOpts
} from '../../../storage';
import {
	getLeadCollection,
	LeadId,
	LeadIds,
	LeadId_Some,
	LeadEntity,
	LeadEntities,
	LeadEntity_Some,
	LeadEntityPatch_Some,
	LeadCollection,
	LeadCollectionState,
	ILeadActions,
	leadActions,
	LeadActionTypes
} from '..';
import {
	readLeads,
	writeLeads,
	ReadLeadsRequest,
	WriteLeadsRequest,
	LeadApiOperation,
	UpsertLeadsByCodeRequest,
	upsertLeadsByCode
} from '../apis';
import { UseCtx } from '../../../config/hooks';
import { ResponseEntities, ContextEntity } from '../collections';

//LIB
import moment from 'moment';
import { saveAs } from 'file-saver';
import * as Excel from 'exceljs';
import _ from 'lodash';

import { isObjectStatusActive, ObjectStatus } from '../models';
import { int } from '@zxing/library/esm/customTypings';

//import { useDispatch } from 'react-redux';
//import { useRequest } from 'redux-query-react';
//import * as lead from '../leads/Lead';

/**
 * Lead helper interface
 *
 * @export
 * @interface ILeadHelper
 * @extends {IEntityHelper}
 */
export interface ILeadHelper extends IEntityHelper {
	// customProperty: any;
	// customMethod(): any;
	// Custom functions
}

/**
 * Lead helper options interface
 *
 * @export
 * @interface LeadHelperOpts
 * @extends {EntityHelperOpts}
 */
export interface LeadHelperOpts extends EntityHelperOpts {
	// customOpt: any;
}

const leadHelperOpts: LeadHelperOpts = {
	...entityHelperDefaultOpts,
	...{}
};

/**
 * Lead helper
 *
 * @export
 * @class LeadHelper
 * @extends {EntityHelper<LeadCollection, LeadActionTypes, LeadActions, LeadEntity, LeadEntities, LeadEntity_Some, LeadEntityPatch_Some, LeadId, LeadIds, LeadId_Some, LeadCollectionState, LeadHelperOpts>}
 * @implements {ILeadHelper}
 */
export class LeadHelper
	extends EntityHelper<
		LeadCollection,
		LeadActionTypes,
		ILeadActions,
		LeadEntity,
		LeadEntities,
		LeadEntity_Some,
		LeadEntityPatch_Some,
		LeadId,
		LeadIds,
		LeadId_Some,
		LeadCollectionState,
		LeadHelperOpts
	>
	implements ILeadHelper {
	constructor() {
		super(
			useSelector(getLeadCollection),
			leadActions,
			useDispatch(),
			leadHelperOpts
		);
		this.collection = useSelector(getLeadCollection);
		this.dispatch = useDispatch();
	}

	lastSuccess(operationId: LeadApiOperation, requestId: string = 'default') {
		return this.filter(
			entity =>
				!!entity.__state?.api?.operations?.[operationId]?.[requestId]?.success
					?.last?.dt
		).reverse()[0]?.__state?.api?.operations?.[operationId]?.[requestId]
			?.success?.last?.dt;
	}

	async read(
		ctx: UseCtx<any>,
		params: Partial<ReadLeadsRequest> = {},
		callback?: any
	): Promise<LeadEntities> {
		if (!ctx.app.user.active()?.userId) return [];
		if (!ctx.lead.service.active() && !params.serviceId) return [];
		//params.modifiedFrom =
		//	params.modifiedFrom || this.lastSuccess(LeadApiOperation.readLeads);

		let request: ReadLeadsRequest = {
			...params,
			...{
				userId: ctx.app.user.active()?.userId || '',
				serviceId: params.serviceId || ctx.lead.service.active()?.id || ''
			}
		};
		if (request.userId === '' || request.serviceId === '') return [];

		let entities: LeadEntities = await readLeads(ctx, request)
			.then((entities: LeadEntities) => {
				if (callback) callback(entities);
				let eventIds: string[] = [];
				let deviceIds: string[] = [];
				let contactIds = entities.map(lead => {
					if (eventIds.indexOf(lead.eventId) === -1) {
						eventIds.push(lead.eventId);
					}
					if (lead.i_?.created?.on && deviceIds.indexOf(lead.i_.created?.on ?? '') === -1) {
						deviceIds.push(lead.i_.created.on);
					}
					return lead.contactId;
				});
				
				

				if (
					entities.length > 0 &&
					contactIds.length > 0 &&
					eventIds.length > 0
				) {
					ctx.lead.eventContactHelper.readEventContactsById(
						ctx,
						{
							eventIds: eventIds,
							ids: contactIds
						},
						function () {
							ctx.lead.deviceHelper.readAll(ctx, { ids: deviceIds }, callback)
						}
					);
				} else {
					if (callback) callback();
				}
				return entities;
			})
			.catch(e => {
				if (callback) callback(e);
				return [];
			});
		return entities;
	}

	async readAllByServiceNoContacts(
		ctx: UseCtx<any>,
		params: Partial<ReadLeadsRequest> = {},
		callback?: any
	): Promise<LeadEntities> {
		if (!ctx.app.user.active()?.userId) return [];
		if (!params.serviceId) return [];
		//params.modifiedFrom =
		//	params.modifiedFrom || this.lastSuccess(LeadApiOperation.readLeads);

		let request: ReadLeadsRequest = {
			...params,
			...{
				userId: ctx.app.user.active()?.userId || '',
				serviceId: params.serviceId || ''
			}
		};
		if (request.userId === '' || request.serviceId === '') return [];

		let entities: LeadEntities = await readLeads(ctx, request)
			.then((entities: LeadEntities) => {
				if (callback) callback(entities);
				return entities;
			})
			.catch(e => {
				if (callback) callback(e);
				return [];
			});
		return entities;
	}

	async write(
		ctx: UseCtx<any>,
		params: Partial<WriteLeadsRequest> = {},
		callback?: any
	): Promise<LeadEntities> {
		if (!ctx.app.user.active()?.userId) return [];
		if (!ctx.lead.service.active()) return [];
		if (!params.leads) return [];
		let request: WriteLeadsRequest = {
			...params,
			...{
				leads: params.leads,
				userId: ctx.app.user.active()?.userId || '',
				serviceId: ctx.lead.service.active()?.id || ''
			}
		};
		if (request.userId === '' || request.serviceId === '') return [];

		let entities: LeadEntities = await writeLeads(ctx, request)
			.then((entities: LeadEntities) => {
				if (callback) callback();
				return entities;
			})
			.catch(e => {
				if (callback) callback(e);
				return [];
			});
		return entities;
	}

	async writeDelete(ctx: UseCtx<any>, id: string): Promise<boolean> {
		let lead = this.get(id);
		if (!lead) return false;

		lead.i_.status = ObjectStatus.Deleted;

		let leads = await this.write(ctx, {
			leads: [lead]
		});

		return leads.length > 0;
	}

	async upsertLeadsByCode(
		ctx: UseCtx<any>,
		params: Partial<UpsertLeadsByCodeRequest> = {},
		callback?: any
	): Promise<LeadEntities> {
		if (!ctx.app.user.active()?.userId) return [];
		if (!ctx.lead.service.active() && !params.serviceId) return [];
		if (!ctx.lead.context.active()) return [];
		if (!params.scans) return [];
		let request: UpsertLeadsByCodeRequest = {
			userId: params.userId || ctx.app.user.active()?.userId || '',
			serviceId: params.serviceId || ctx.lead.service.active()?.id || '',
			eventIds: params.eventIds || ctx.lead.context.active()?.eventIds || [],
			scans: params.scans || []
		};
		if (
			request.userId === '' ||
			request.serviceId === '' ||
			request.eventIds === [] ||
			request.scans === []
		) {
			return [];
		}

		let entities: LeadEntities = await upsertLeadsByCode(ctx, request)
			.then((entities: LeadEntities) => {
				if (callback) callback();
				return entities;
			})
			.catch(e => {
				if (callback) callback(e);
				return [];
			});
		return entities;
	}

	allByServiceIdAndEventIds(
		serviceId: string,
		eventIds: string[]
	): LeadEntities {
		return this.all()
			.filter(
				lead => lead.serviceId === serviceId && eventIds.includes(lead.eventId)
			)
			.filter(isObjectStatusActive);
	}

	allByServiceIdAndEventIdsAndUserId(
		serviceId: string,
		eventIds: string[],
		userId: string
	): LeadEntities {
		return this.all()
			.filter(
				lead =>
					lead.serviceId === serviceId &&
					eventIds.includes(lead.eventId) &&
					lead.i_.created?.by === userId
			)
			.filter(isObjectStatusActive);
	}

	allByServiceIdAndUserId(serviceId: string, userId: string): LeadEntities {
		return this.all()
			.filter(
				lead => lead.serviceId === serviceId && lead.i_.created?.by === userId
			)
			.filter(isObjectStatusActive);
	}

	countByServiceIdAndEventIdsAndUserId(
		serviceId: string,
		eventIds: string[],
		userId: string
	): number {
		return this.allByServiceIdAndEventIdsAndUserId(serviceId, eventIds, userId)
			.length;
	}

	async readActiveData(ctx: UseCtx<any>, callback?: any) {
		let userId: string = ctx.app.user.active()?.userId || '';
		let leadId: string = ctx.lead.lead.active()?.id || '';
		if (userId !== '' && leadId !== '') {
			/*
			ctx.lead.lead.read(ctx, { leadIds: [leadId] }, () => {
				console.log('Lead data loaded: Lead');
			});
			*/
			ctx.lead.response.read(
				ctx,
				{ leadIds: [leadId] },
				(responses: ResponseEntities) => {
					let qualifierIds: string[] = [],
						qualifierValueIds: string[] = [];
					responses.forEach(response => {
						qualifierIds = _.union(qualifierIds, [response.qualifierId]);
						qualifierValueIds = _.union(
							qualifierValueIds,
							response.qualifierValueId ? [response.qualifierValueId] : []
						);
					});
					console.log('Lead data loaded: Responses');
				}
			);
			ctx.lead.note.read(ctx, { leadIds: [leadId] }, () => {
				console.log('Lead data loaded: Notes');
			});

			if (callback) callback();
		}
	}

	// Exporting services
	async exportLeadsByHour(
		ctx: UseCtx<any>,
		context: ContextEntity,
		leads: LeadEntities,
		onUpdate?: (message: string) => void,
		onError?: (message: string) => void,
		onSuccess?: () => void
	): Promise<any> {
		if (onUpdate) {
			onUpdate('Building Data Set...');
		}

		// All data is now loaded
		if (onUpdate) {
			onUpdate('Generating File...');
		}

		let workbook = new Excel.Workbook();
		let worksheetLeadScans = workbook.addWorksheet('AllLeadScans');
		let worksheetScanByMinReport = workbook.addWorksheet('ScanByMinReport');
		let worksheetScanByHourReport = workbook.addWorksheet('ScanByHourReport');
		let colsLeadScans = [
			{
				header: 'Data',
				key: 'data'
			},
			{
				header: 'Date Time',
				key: 'datetime'
			}
		];
		let colsScanByMinReport = [
			{
				header: 'Date',
				key: 'date'
			},
			{
				header: 'Time',
				key: 'time'
			},
			{
				header: 'Scans',
				key: 'scans'
			}
		];
		let colsScanByHourReport = [
			{
				header: 'Date',
				key: 'date'
			},
			{
				header: 'Time',
				key: 'time'
			},
			{
				header: 'Scans',
				key: 'scans'
			}
		];

		worksheetLeadScans.columns = colsLeadScans;
		worksheetLeadScans.columns.forEach(column => {
			if (column.header) {
				column.width = column.header.length < 15 ? 15 : column.header.length;
			}
		});
		worksheetScanByMinReport.columns = colsScanByMinReport;
		worksheetScanByMinReport.columns.forEach(column => {
			if (column.header) {
				column.width = column.header.length < 15 ? 15 : column.header.length;
			}
		});
		worksheetScanByHourReport.columns = colsScanByHourReport;
		worksheetScanByHourReport.columns.forEach(column => {
			if (column.header) {
				column.width = column.header.length < 15 ? 15 : column.header.length;
			}
		});

		let lastMinDateTime: Date | undefined = undefined;
		let minCount: int = 0;
		let lastHourDateTime: Date | undefined = undefined;
		let hourCount: int = 0;

		leads!
			.sort((a: LeadEntity | any, b: LeadEntity | any) =>
				a.i_.created.dt &&
				b.i_.created.dt &&
				moment(a.i_.created.dt).isBefore(moment(b.i_.created.dt))
					? -1
					: 1
			)
			.forEach(item => {
				//min counter
				minCount += 1;
				if (
					lastMinDateTime &&
					moment(lastMinDateTime).local().format('YYYY-MM-DD LT') !=
						moment(item.i_.created.dt).local().format('YYYY-MM-DD LT')
				) {
					worksheetScanByMinReport.addRow(
						{
							date: moment(item.i_.created.dt).local().format('YYYY-MM-DD'),
							time: moment(item.i_.created.dt).local().format('LT'),
							scans: minCount
						},
						''
					);
					minCount = 0;
					lastMinDateTime = moment(item.i_.created.dt).toDate();
				}
				if (!lastMinDateTime) {
					lastMinDateTime = moment(item.i_.created.dt).toDate();
				}

				//hour counter
				hourCount += 1;
				if (
					lastHourDateTime &&
					moment(lastHourDateTime).local().format('YYYY-MM-DD HH') !=
						moment(item.i_.created.dt).local().format('YYYY-MM-DD HH')
				) {
					worksheetScanByHourReport.addRow(
						{
							date: moment(item.i_.created.dt).local().format('YYYY-MM-DD'),
							time:
								moment(item.i_.created.dt).local().format('hh:00 A') +
								' - ' +
								moment(item.i_.created.dt)
									.local()
									.add(1, 'hours')
									.format('hh:00 A'),
							scans: hourCount
						},
						''
					);
					hourCount = 0;
					lastHourDateTime = moment(item.i_.created.dt).toDate();
				}
				if (!lastHourDateTime) {
					lastHourDateTime = moment(item.i_.created.dt).toDate();
				}

				//all scans
				worksheetLeadScans.addRow(
					{
						data: item.scanCode,
						datetime: moment(item.i_.created.dt).local().format('YYYY-MM-DD LT')
					},
					''
				);
			});

		const buffer = await workbook.xlsx.writeBuffer();
		const fileType =
			'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
		const fileExtension = '.xlsx';

		const blob = new Blob([buffer], {
			type: fileType
		});

		saveAs(
			blob,
			(context!.name || 'Context') +
				'LeadsByHour-' +
				moment().format('YYYY-MM-DD-hmm') +
				fileExtension
		);

		if (onUpdate) {
			onUpdate('Complete');
		}
		if (onSuccess) {
			onSuccess();
		}
	}
}
