import React from 'react';

//Data
import { LeadEntities, QualifierEntities } from '../../../lead/store';
import { useCtx } from '../../../config/hooks';

//Lib
import moment from 'moment';

//UI
import {
	IonButton,
	IonGrid,
	IonRow,
	IonCol,
	IonCard,
	IonItem,
	IonCardHeader,
	IonCardContent,
	IonCardTitle,
	IonImg,
	IonList,
	IonToolbar,
	IonButtons,
	IonBackButton,
	IonContent
} from '@ionic/react';

const EventView: React.FC = () => {
	const ctx = useCtx<{}>({});
	const {
		config: [config],
		lead: { activeService, activeEvent }
	} = ctx;

	if (!activeService) return <>No Service selected</>;
	if (!activeEvent) return <>No Event selected</>;

	//Leads Information
	let leadScanDates: string[] = [];
	let allLeads: LeadEntities = ctx.lead.lead.allByServiceIdAndEventIds(
		activeService.id,
		[activeEvent.id]
	);
	let totalLeads = allLeads.length;
	allLeads.forEach(lead => {
		if (lead.i_.created && leadScanDates.includes(lead.i_.created?.dt || '')) {
			leadScanDates.push(lead.i_.created?.dt || '');
		}
	});
	const leadsByDayItems = leadScanDates.map(
		(day: string, index: number, leadScanDays: string[]) => {
			return (
				<IonRow>
					<IonCol sizeMd="6">{moment(day).format('YYYY-MM-DD')}</IonCol>
					<IonCol sizeMd="6">
						{allLeads.filter(lead =>
							moment(lead.i_.created?.dt).isSame(day, 'day')
						).length || 0}
					</IonCol>
				</IonRow>
			);
		}
	);

	//Representative Information
	let allRepresentitives = ctx.lead.user
		.allByService(activeService)
		.filter(user => {
			if (
				user?.email &&
				user?.email?.toLowerCase().trim().indexOf('@conexsys.com') > -1
			) {
				return false;
			}
			return true;
		});

	const representativeItems = allRepresentitives.map(user => {
		return (
			<IonItem lines="full">
				<IonGrid>
					<IonRow>
						<IonCol sizeMd="6">{user.name}</IonCol>
						<IonCol sizeMd="6">{user.email}</IonCol>
					</IonRow>
				</IonGrid>
			</IonItem>
		);
	});

	//Licenses Information
	let contexts = ctx.lead.context.allByEventId(activeEvent.id);
	let licenses = ctx.lead.license.allByContexts(
		contexts,
		activeService ? [activeService] : undefined
	);
	let totalAppLicenses = licenses
		.filter(license => license.serviceType === 'lead-app')
		.reduce((sum, current) => sum + current.quantity, 0);
	let totalAPILicenses = licenses
		.filter(license => license.serviceType === 'lead-api')
		.reduce((sum, current) => sum + current.quantity, 0);
	let totalSSTLicenses = licenses
		.filter(license => license.serviceType === 'sst')
		.reduce((sum, current) => sum + current.quantity, 0);
	let totalOpticonLicenses = licenses
		.filter(license => license.serviceType === 'opticon')
		.reduce((sum, current) => sum + current.quantity, 0);

	//Qualifier Information
	let allQualifiers: QualifierEntities = ctx.lead.qualifier.allByServiceIdAndEventIds(
		activeService.id,
		[activeEvent.id]
	);

	let totalQualifiers = allQualifiers.length;
	let qualifierTypes = [
		'Qualifiers',
		'Questions',
		'Products',
		'Services',
		'Follow-ups'
	];
	let qualifiersItems = qualifierTypes.map(qualifierType => {
		let typeCount = allQualifiers.filter(
			qualifier => qualifier.category === qualifierType
		).length;
		return (
			<IonRow>
				<IonCol sizeMd="10">{qualifierType}</IonCol>
				<IonCol sizeMd="2">{typeCount}</IonCol>
			</IonRow>
		);
	});

	return (
		<IonContent>
			<IonToolbar>
				<IonButtons slot="start">
					<IonBackButton text="Back" defaultHref="/lead-service/events" />
				</IonButtons>
			</IonToolbar>
			<IonGrid>
				<IonRow>
					<IonCol>
						<IonCard>
							<IonCardContent>
								<IonGrid>
									<IonRow>
										<IonCol size="12" sizeMd="3">
											<IonImg hidden={((activeEvent.header?.OriginalFilePath || "") == "")} src={activeEvent.header?.OriginalFilePath || ""} />
										</IonCol>
										<IonCol size="12" sizeMd="9">
											<IonCardTitle>
												{activeEvent.name} ({activeEvent.code})
											</IonCardTitle>
											<br />
											Date:
											{moment(activeEvent.start).format('dddd MMMM Do, YYYY')}
											<br />
											Location: {activeEvent.location}
											<br />
										</IonCol>
									</IonRow>
								</IonGrid>
							</IonCardContent>
						</IonCard>
					</IonCol>
				</IonRow>
				<IonRow>
					<IonCol size="6">
						<IonCard>
							<IonCardHeader>
								<IonCardTitle>
									Lead Break Down{' '}
									<IonButton
										class="ion-float-right"
										size="small"
										routerLink="/lead-service/leads"
									>
										Manage
									</IonButton>
								</IonCardTitle>
							</IonCardHeader>
							<IonCardContent>
								<IonGrid>
									<IonRow>
										<IonCol sizeMd="6">Total Leads</IonCol>
										<IonCol sizeMd="6">{totalLeads}</IonCol>
									</IonRow>
									{leadsByDayItems}
								</IonGrid>
							</IonCardContent>
						</IonCard>
						<IonCard>
							<IonCardHeader>
								<IonCardTitle>
									Qualifiers
									<IonButton
										class="ion-float-right"
										size="small"
										routerLink="/lead-service/qualifiers"
									>
										Manage
									</IonButton>
								</IonCardTitle>
							</IonCardHeader>
							<IonCardContent>
								<IonGrid>
									<IonRow>
										<IonCol sizeMd="10">All Qualifiers</IonCol>
										<IonCol sizeMd="2">{totalQualifiers}</IonCol>
									</IonRow>
									{qualifiersItems}
								</IonGrid>
							</IonCardContent>
						</IonCard>
					</IonCol>
					<IonCol size="6">
						<IonCard>
							<IonCardHeader>
								<IonCardTitle>
									Licenses
									<IonButton
										class="ion-float-right"
										size="small"
										routerLink="/lead-service/licenses"
									>
										Manage
									</IonButton>
								</IonCardTitle>
							</IonCardHeader>
							<IonCardContent>
								<IonGrid>
									<IonRow hidden={(totalAppLicenses || 0) <= 0}>
										<IonCol sizeMd="6">App Licenses</IonCol>
										<IonCol sizeMd="6">{totalAppLicenses}</IonCol>
									</IonRow>
									<IonRow hidden={(totalAPILicenses || 0) <= 0}>
										<IonCol sizeMd="6">API Licenses</IonCol>
										<IonCol sizeMd="6">{totalAPILicenses}</IonCol>
									</IonRow>
									<IonRow hidden={(totalSSTLicenses || 0) <= 0}>
										<IonCol sizeMd="6">SST Licenses</IonCol>
										<IonCol sizeMd="6">{totalSSTLicenses}</IonCol>
									</IonRow>
									<IonRow hidden={(totalOpticonLicenses || 0) <= 0}>
										<IonCol sizeMd="6">Opticon Licenses</IonCol>
										<IonCol sizeMd="6">{totalOpticonLicenses}</IonCol>
									</IonRow>
								</IonGrid>
							</IonCardContent>
						</IonCard>
						<IonCard>
							<IonCardHeader>
								<IonCardTitle>
									Representatives
									<IonButton
										class="ion-float-right"
										size="small"
										routerLink="/lead-service/users"
									>
										Manage
									</IonButton>
								</IonCardTitle>
							</IonCardHeader>
							<IonCardContent>
								<IonList>{representativeItems}</IonList>
							</IonCardContent>
						</IonCard>
					</IonCol>
				</IonRow>
			</IonGrid>
		</IonContent>
	);
};

export default EventView;
