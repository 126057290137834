import { useSelector, useDispatch } from 'react-redux';
import EntityHelper from '../../../storage/classes/Entity';
import {
	IEntityHelper,
	EntityHelperOpts,
	entityHelperDefaultOpts
} from '../../../storage';
import {
	getServiceCollection,
	ServiceId,
	ServiceIds,
	ServiceId_Some,
	ServiceEntity,
	ServiceEntities,
	ServiceEntity_Some,
	ServiceEntityPatch_Some,
	ServiceCollection,
	ServiceCollectionState,
	IServiceActions,
	serviceActions,
	ServiceActionTypes
} from '..';
import {
	readServices,
	ReadServicesRequest,
	readServicesAll,
	ReadServicesAllRequest,
	WriteServicesRequest,
	writeServices,
	ServiceApiOperation,
	readLicensesAll,
	writeLicenses,
	readContacts,
	writeContacts
} from '../apis';
import { UseCtx } from '../../../config/hooks';
import _ from 'lodash';
import {
	ContextEntity,
	LicenseEntities,
	EventContactOrganizationEntity,
	ContactOrganizationEntity,
	ContactEntity,
	ContactEntities,
	EventEntities
} from '../collections';

//LIB
import moment from 'moment';
import { saveAs } from 'file-saver';
import * as Excel from 'exceljs';

import {
	isObjectStatusActive,
	ObjectIds,
	ObjectType,
	ServiceType,
	ContactType,
	LicenseServiceType,
	ObjectStatus
} from '../models';
import { newEntity } from '../../../app/utils';

/**
 * Service helper interface
 *
 * @export
 * @interface IServiceHelper
 * @extends {IEntityHelper}
 */
export interface IServiceHelper extends IEntityHelper {
	// customProperty: any;
	// customMethod(): any;
	// Custom functions
}

/**
 * Service helper options interface
 *
 * @export
 * @interface ServiceHelperOpts
 * @extends {EntityHelperOpts}
 */
export interface ServiceHelperOpts extends EntityHelperOpts {
	// customOpt: any;
}

const serviceHelperOpts: ServiceHelperOpts = {
	...entityHelperDefaultOpts,
	...{}
};

export interface ServiceEntityFilter {
	keyWords?: string;
	eventIds?: string[];
	contactIds?: string[];
	contextIds?: string[];
}

export interface ServiceHelperImportEventContactResults {
	context: ContextEntity;
	contact: ContactEntity;
	service: ServiceEntity;
}

/**
 * Service helper
 *
 * @export
 * @class ServiceHelper
 * @extends {EntityHelper<ServiceCollection, ServiceActionTypes, ServiceActions, ServiceEntity, ServiceEntities, ServiceEntity_Some, ServiceEntityPatch_Some, ServiceId, ServiceIds, ServiceId_Some, ServiceCollectionState, ServiceHelperOpts>}
 * @implements {IServiceHelper}
 */
export class ServiceHelper
	extends EntityHelper<
		ServiceCollection,
		ServiceActionTypes,
		IServiceActions,
		ServiceEntity,
		ServiceEntities,
		ServiceEntity_Some,
		ServiceEntityPatch_Some,
		ServiceId,
		ServiceIds,
		ServiceId_Some,
		ServiceCollectionState,
		ServiceHelperOpts
	>
	implements IServiceHelper {
	constructor() {
		super(
			useSelector(getServiceCollection),
			serviceActions,
			useDispatch(),
			serviceHelperOpts
		);
		this.collection = useSelector(getServiceCollection);
		this.dispatch = useDispatch();
	}

	lastSuccess(operationId: ServiceApiOperation, requestId: string = 'default') {
		return this.filter(
			entity =>
				!!entity.__state?.api?.operations?.[operationId]?.[requestId]?.success
					?.last?.dt
		).reverse()[0]?.__state?.api?.operations?.[operationId]?.[requestId]
			?.success?.last?.dt;
	}

	async read(
		ctx: UseCtx<any>,
		params: Partial<ReadServicesRequest> = {},
		callback?: any
	): Promise<ServiceEntities> {
		if (!ctx.app.user.active()?.userId) return [];
		//params.modifiedFrom =
		//	params.modifiedFrom || this.lastSuccess(ServiceApiOperation.readServices);

		let request: ReadServicesRequest = {
			...params,
			...{
				userId: ctx.app.user.active()?.userId || ''
			}
		};
		if (request.userId === '') return [];

		let entities: ServiceEntities = await readServices(ctx, request)
			.then((entities: ServiceEntities) => {
				if (callback) callback(entities);
				let contactIds: string[] = [];
				entities.forEach(service => {
					if (service.contactIds) {
						contactIds.concat(service.contactIds);
					}
				});
				if (
					entities.length > 0 &&
					contactIds.length > 0 &&
					ctx.lead.service.active()?.eventIds
				) {
					ctx.lead.contactHelper.readContactsById(
						ctx,
						{
							ids: contactIds
						},
						callback
					);
				}
				return entities;
			})
			.catch(e => {
				if (callback) callback(e);
				return [];
			});
		return entities;
	}

	async readAll(
		ctx: UseCtx<any>,
		params: Partial<ReadServicesAllRequest> = {},
		callback?: any
	): Promise<ServiceEntities> {
		if (!ctx.app.user.active()?.userId) return [];
		let request: ReadServicesAllRequest = {
			...params,
			...{
				userId: ctx.app.user.active()?.userId || ''
			}
		};

		let entities: ServiceEntities = await readServicesAll(ctx, request)
			.then((entities: ServiceEntities) => {
				if (callback) callback(entities);
				let contactIds: string[] = [];
				let userIds: string[] = [];
				entities.forEach(service => {
					if (service.contactIds?.length || 0 > 0) {
						contactIds.push(...(service.contactIds || []));
					}
					if (service.userIds?.length || 0 > 0) {
						userIds.push(...(service.userIds || []));
					}
				});
				if (contactIds.length > 0) {
					ctx.lead.contactHelper.readContactsById(ctx, {
						ids: contactIds
					});
				}
				if (userIds.length > 0) {
					let chunkSize = 100;
					for (let i = 0; i < userIds.length; i += chunkSize) {
						ctx.lead.userHelper.readAll(ctx, {
							ids: userIds.slice(i, i + chunkSize)
						});
					}
				}
				return entities;
			})
			.catch(e => {
				console.log(e);
				if (callback) callback(e);
				return [];
			});

		return entities;
	}

	async write(
		ctx: UseCtx<any>,
		params: Partial<WriteServicesRequest> = {},
		callback?: any
	): Promise<ServiceEntities> {
		if (!ctx.app.user.active()?.userId) return [];
		if (!params.services) return [];
		let request: WriteServicesRequest = {
			...params,
			...{
				services: params.services,
				userId: ctx.app.user.active()?.userId || ''
			}
		};
		if (request.userId === '') return [];

		let entities: ServiceEntities = await writeServices(ctx, request)
			.then((entities: ServiceEntities) => {
				if (callback) callback();
				return entities;
			})
			.catch(e => {
				if (callback) callback(e);
				return [];
			});
		return entities;
	}

	allByUserId(userId?: string): ServiceEntities {
		if (userId !== undefined && userId.length > 0) {
			return this.all()
				.filter(service => service.userIds?.includes(userId))
				.filter(isObjectStatusActive);
		} else {
			return [];
		}
	}

	async readActiveData(ctx: UseCtx<any>, callback?: any) {
		let serviceId: string = ctx.lead.service.active()?.id || '';
		if (serviceId !== '') {
			ctx.lead.event.read(ctx, undefined, (results: EventEntities) => {
				console.log('Service data loaded: Events');

				if (results) {
					let eventIds: string[] = results.map(e => e.id);
					ctx.lead.context.readAll(ctx, { eventIds: eventIds }, () => {
						console.log('Service data loaded: Contexts');
					});
				}
			});

			ctx.lead.license.readAll(ctx, { serviceIds: [serviceId] }, () => {
				console.log('Service data loaded: Licenses');
			});
			ctx.lead.user.readAll(
				ctx,
				{
					ids:
						(ctx.lead.service.active()?.userIds?.length || 0) > 0
							? ctx.lead.service.active()?.userIds
							: ['']
				},
				() => {
					console.log('Service data loaded: Users');
				}
			);
			ctx.lead.order.read(ctx, undefined, () => {
				console.log('Service data loaded: Orders');
			});
			ctx.lead.device.readAll(
				ctx,
				{
					ids:
						(ctx.lead.service.active()?.deviceIds?.length || 0) > 0
							? ctx.lead.service.active()?.deviceIds
							: ['']
				},
				() => {
					console.log('Service data loaded: Devices');
				}
			);
			ctx.lead.lead.read(ctx, undefined, () => {
				console.log('Service data loaded: Leads');
			});
			ctx.lead.response.read(ctx, undefined, () => {
				console.log('Service data loaded: Responses');
			});
			ctx.lead.note.read(ctx, undefined, () => {
				console.log('Service data loaded: Notes');
			});
			ctx.lead.qualifier.read(ctx, undefined, () => {
				console.log('Service data loaded: Qualifiers');
			});
			ctx.lead.qualifierValue.read(ctx, undefined, () => {
				console.log('Service data loaded: Qualifier Values');
			});

			if (callback) callback();
		}
	}

	setDefault(ctx: UseCtx<any>, services?: ServiceEntities) {
		let activeUser = ctx.app.user.active();
		// if there is no active user. don't try to set the active service
		if (!activeUser || !activeUser.userId) return;
		let activeService = this.active();
		// if there is a current active service and it's for the active user. then leave it as it. no need for the default.
		if (
			activeService &&
			(activeService.userIds?.includes(activeUser.userId) ||
				activeUser.security.includes('administrator'))
		)
			return;

		// filter all user services and sort them descending by their modified date and set the latest modified (first) service as the active service
		this.set(
			(services || this.all())
				.filter(service => service.userIds?.includes(activeUser?.userId || ''))
				.filter(isObjectStatusActive)
				.sort((a: ServiceEntity | any, b: ServiceEntity | any) =>
					a.i_.modified.dt &&
					b.i_.modified.dt &&
					moment(a.i_.modified.dt).isAfter(moment(b.i_.modified.dt))
						? -1
						: 1
				)[0]?.id
		);
	}

	allByFilter(ctx: UseCtx<any>, filter: ServiceEntityFilter) {
		return this.all()
			.filter(isObjectStatusActive)
			.filter(service =>
				filter.contextIds?.find(val => service.contextIds?.includes(val))
			)
			.filter(service => {
				let match = 0,
					matches = 0,
					keywords = filter.keyWords ?? '';

				if (!service) {
					return false;
				}

				for (let keyword of keywords.toLowerCase().split(' ')) {
					if (keyword === '') break;
					match++;
					if (
						service.name &&
						service.name?.toLowerCase().trim().indexOf(keyword) > -1
					) {
						matches++;
					}
				}
				return match === matches;
			});
	}

	allByContextIds(contextIds: ObjectIds) {
		return this.all()
			.filter(isObjectStatusActive)
			.filter(service =>
				contextIds.find(val => service.contextIds?.includes(val))
			);
	}

	async importServiceFromEventContact(
		ctx: UseCtx<any>,
		eventContact: EventContactOrganizationEntity,
		contextId: string,
		onError: (message: string) => void,
		onSuccess?: (results: ServiceHelperImportEventContactResults) => void
	) {
		let userId: string = ctx.app.activeUser?.userId || '';
		if (userId === '') {
			onError(
				'Unable to activate without a valid user account. Please Sign-in'
			);
			return;
		}

		let context: ContextEntity | undefined = ctx.lead.contextHelper.get(
			contextId
		);
		if (!context) {
			onError('Unable to activate without a valid context account.');
			return;
		}

		let contact: ContactEntity | undefined,
			service: ServiceEntity | undefined,
			errorMessage: string | undefined;

		let contacts = (await readContacts(ctx, {
			userId: userId,
			contactType: ContactType.Organization,
			name: eventContact.name
		})
			.then((result: ContactEntities) => {
				return result;
			})
			.catch(e => {
				errorMessage =
					'Contact request error: ' + e.response?.data?.message || e.message;
				return;
			})) as ContactEntities;

		if (contacts.length === 0) {
			contacts = (await writeContacts(ctx, {
				userId: userId,
				contacts: [
					{
						...newEntity(ctx, ObjectType.Contact),
						type: ContactType.Organization,
						name: eventContact.name,
						email: eventContact.email,
						contactEmail: eventContact.contactEmail,
						contactFirstName: eventContact.contactFirstName,
						contactLastName: eventContact.contactLastName,
						contactPhone: eventContact.contactPhone,
						address1: eventContact.address1,
						address2: eventContact.address2,
						address3: eventContact.address3,
						city: eventContact.city,
						subdivision: eventContact.subdivision,
						postal: eventContact.postal,
						phone: eventContact.phone,
						country: eventContact.country,
						code: eventContact.code,
						accessCode: eventContact.accessCode
					}
				]
			})
				.then((result: ContactEntities) => {
					return result;
				})
				.catch(e => {
					errorMessage =
						'Contact write error: ' + e.response?.data?.message || e.message;
					return;
				})) as ContactEntities;
		} else {
			contact = contacts[0];
			//Set contact to active
			contact.i_.status = ObjectStatus.Active;
			contacts = (await writeContacts(ctx, {
				userId: userId,
				contacts: [
					{
						...contact,
						type: ContactType.Organization,
						name: eventContact.name,
						email: eventContact.email,
						contactEmail: eventContact.contactEmail,
						contactFirstName: eventContact.contactFirstName,
						contactLastName: eventContact.contactLastName,
						contactPhone: eventContact.contactPhone,
						address1: eventContact.address1,
						address2: eventContact.address2,
						address3: eventContact.address3,
						city: eventContact.city,
						subdivision: eventContact.subdivision,
						postal: eventContact.postal,
						phone: eventContact.phone,
						country: eventContact.country,
						code: eventContact.code,
						accessCode: eventContact.accessCode
					}
				]
			})
				.then((result: ContactEntities) => {
					return result;
				})
				.catch(e => {
					errorMessage =
						'Contact write error: ' + e.response?.data?.message || e.message;
					return;
				})) as ContactEntities;
		}

		if (errorMessage) {
			onError(errorMessage);
			return;
		}

		if (contacts.length === 0) {
			onError('Unable to create organization contact');
			return;
		}
		contact = contacts[0];

		if (!contact) {
			onError('Unable to process order contact');
			return;
		}

		// get and create / update the organization service for the order organization contact
		let services = (await readServicesAll(ctx, {
			userId: userId,
			serviceType: ServiceType.Organization,
			contactIds: [contact.id]
		})
			.then((result: ServiceEntities) => {
				return result;
			})
			.catch(e => {
				errorMessage =
					'Service request error: ' + e.response?.data?.message || e.message;
				return;
			})) as ServiceEntities;

		if (errorMessage) {
			onError(errorMessage);
			return;
		}

		if (services.length === 0) {
			console.log('Adding service in API');
			service = {
				...newEntity(ctx, ObjectType.Service),
				type: ServiceType.Organization,
				name: contact.name
			};
		} else {
			service = services[0];
			service.i_.status = ObjectStatus.Active;
		}
		service.contactIds = _.union(service.contactIds, [contact.id]);
		service.userIds = _.union(service.userIds, [userId]);
		service.contextIds = _.union(service.contextIds, [contextId]);
		service.eventIds = _.union(service.eventIds, context.eventIds);
		services = (await writeServices(ctx, {
			userId: userId,
			services: [service]
		})
			.then((result: ServiceEntities) => {
				console.log('Upserted service');
				return result;
			})
			.catch(e => {
				errorMessage =
					'Service update error: ' + e.response?.data?.message || e.message;
				return;
			})) as ServiceEntities;

		if (errorMessage) {
			onError(errorMessage);
			return;
		}

		if (services.length === 0) {
			onError('Unable to process order service');
			return;
		}
		service = services[0];
		let results: ServiceHelperImportEventContactResults = {
			context,
			contact,
			service
		};
		if (onSuccess) onSuccess(results);
		return results;
	}

	async sendServiceLoginEmail(
		ctx: UseCtx<any>,
		service: ServiceEntity,
		context: ContextEntity,
		licenses: LicenseEntities,
		to: string,
		cc?: string,
		bcc?: string,
		onError?: (message: string) => void,
		onSuccess?: (result: any) => void
	): Promise<any> {
		try {
			let eventName: string = context.name || '';
			let mobileLicenses: LicenseEntities = licenses.filter(
				l => l.serviceType === LicenseServiceType.LeadApp
			);
			let opticonLicenses: LicenseEntities = licenses.filter(
				l => l.serviceType === LicenseServiceType.Opticon
			);
			let apiLicenses: LicenseEntities = licenses.filter(
				l => l.serviceType === LicenseServiceType.LeadApi
			);
			let sstLicenses: LicenseEntities = licenses.filter(
				l => l.serviceType === LicenseServiceType.Sst
			);
			let mobileLicenseContent =
				mobileLicenses?.length > 0
					? `
			<hr
              style="
                border: 2px solid #eaeef3;
                border-bottom: 0;
                margin: 20px 0;
              "
            />
			<p style="font-size: 20px;font-weight:bold;">
				MyLeads Mobile App
			</p>
			<p>
				<a
					href="https://conx.is/la"
					target="_blank"
					style="
					width: 300px;
					padding: 7px;
					cursor: pointer;
					background: #202ACB;
					color: #fff;
					border-radius: 5px;
					border: 1px solid #202ACB;
					text-decoration: none;
					font-size: 12px;
					"
					>Download the app</a
				>
			</p>
			<p>
			  <br />
              Instructions on how to setup your mobile lead scanning devices can
              be found in our
              <a
                href="https://leads.conexsys.com/assets/docs/CONEXSYS_MyLEADS%20Mobile%20App%20-%20Quick%20Start%20Guide.pdf"
                target="_blank"
              >
                Quick Start Guide.
              </a>
            </p><p>
			After you have created/activated your scanning account, you can scan the QR Code below to activate your mobile scanning device. <br /> Please be sure to share this email with all representatives that will be using the CONEXSYS lead retrieval scanner at your booth onsite. They will need to create/login to their own account in order to scan at the event.</p>
			<p>
			  <br />
              <table cellspacing="0" cellpadding="0" border="0" width="600">
			  <tbody>
			  ${mobileLicenses
					?.map(l => {
						return `<tr>
					<td width='180' height='180'>
						<img src='https://myconexsys.com/Home/GenerateQRCode?data=${
							l.code
						}' width='120' height='120' />
					</td>
					<td>
						<strong>Activation Code:  ${l.code}</strong><br />
						<strong>Connect Up to ${l.quantity.toString()} Devices</strong><br />
					</td>
				</tr>`;
					})
					.join('')}
				</tbody>
				</table>
            </p>
			<p>
			<b>Please Note:</b> All onsite reps will need to create an account
              in order to activate their scanning devices and/or access the
              portal. The CONEXSYS Lead Retrieval MyLEADS - Mobile App requires an internet connection to operate. Physical structures and/or signal disturbances can disrupt/weaken Wi-Fi and data connections. If your device does not have a connection, you can either purchase internet services through the event center (if offered) or operate the app offline. To do this, turn your phone on airplane mode. The app will only capture 6 digit codes but once you establish an internet connection, your information will be synced.</p>
			`
					: '';

			let apiLicenseContent =
				apiLicenses?.length > 0
					? `
			<hr
              style="
                border: 2px solid #eaeef3;
                border-bottom: 0;
                margin: 20px 0;
              "
            />
			<p style="font-size: 20px;font-weight:bold;">
				MyLeads API Connection
			</p>
			<p>
			  <br />
              Instructions on how to use the Conexsys Lead API can be found at
              <a
                href="https://api.conexsys.com/lead/v2/docs/"
                target="_blank"
              >
                https://api.conexsys.com/lead/v2/docs/
              </a>
              <br />
			  Test badges can be accessed by contacting our <a target="_blank" href="https://tawk.to/ConexsysLeads">Support Team</a>. 
            </p>
			<p>
			  <br />
              <table cellspacing="0" cellpadding="0" border="0" width="600">
			  <tr><td><strong>API Access</strong></td></tr>
			  ${apiLicenses
					?.map(l => {
						return `<tr>
					<td>
						<strong>License Id:  </strong>${l.id}<br />
						<strong>License Key:  </strong>${l.code}<br />
						<br />
					</td>
				</tr>`;
					})
					.join('')}
				</table>
            </p>`
					: '';

			let opticonLicenseCount =
				opticonLicenses.length > 0
					? opticonLicenses
							.map(l => {
								return l.quantity;
							})
							.reduce((a, b) => {
								return a + b;
							})
					: 0;
			let opticonLicenseContent =
				opticonLicenseCount > 0
					? `
			<hr
              style="
                border: 2px solid #eaeef3;
                border-bottom: 0;
                margin: 20px 0;
              "
            />
			<p style="font-size: 20px;font-weight:bold;">
				MyLeads Opticon
			</p>
			<p>
				<br />
				Your handheld Opticon scanner devices have been reserved and will be available to collect onsite at the Lead Retrieval Service Desk. No additional steps are required at this time. You are able to add and manage your custom qualifiers from the CONEXSYS Lead Portal anytime before and during the event. 
            </p>
			<p>
			  <br />
              <table cellspacing="0" cellpadding="0" border="0" width="600">
			  <tr>
					<td width='180'>
						<img src='https://leads.conexsys.com/assets/img/Opticon_9725.jpg' width='150' />
					</td>
					<td>
						<strong>Quantity: ${opticonLicenseCount}</strong><br />
					</td>
				</tr>
				</table>
            </p>`
					: '';

			let sstLicenseCount =
				sstLicenses.length > 0
					? sstLicenses
							.map(l => {
								return l.quantity;
							})
							.reduce((a, b) => {
								return a + b;
							})
					: 0;
			let sstLicenseContent =
				sstLicenseCount > 0
					? `
			<hr
              style="
                border: 2px solid #eaeef3;
                border-bottom: 0;
                margin: 20px 0;
              "
            />
			<p style="font-size: 20px;font-weight:bold;">
				MyLeads SST
			</p>
			<p>
			  <br />
              Your SST devices will be avaliable at the Lead service desk onsite.
            </p>
			<p>
			  <br />
              <table cellspacing="0" cellpadding="0" border="0" width="600">
			  <tr>
					<td width='180'>
						<img src='https://leads.conexsys.com/assets/img/bc500dc.jpg' width='150' />
					</td>
					<td>
						<strong>Quantity: ${sstLicenseCount}</strong><br />
					</td>
				</tr>
				</table>
            </p>`
					: '';

			const response = await ctx.app.api.sendEmail({
				from: 'CONEXSYS Lead Retrieval<no-reply@conexsysleads.com>',
				sender: 'no-reply@conexsysleads.com',
				to,
				cc,
				bcc,
				subject: 'CONEXSYS Lead Retrieval Login - ' + service.name?.trim(),
				html: `<div>
  <table
    style="
      width: 650px;
      margin: 0;
      padding: 0;
      font-family: 'ProximaNova', sans-serif;
      height: 100% !important;
      border: 1px solid #000;
    "
    border="0"
    cellpadding="0"
    cellspacing="0"
    height="100%"
    width="100%"
  >
    <tbody>
      <tr>
        <td
          align="center"
          valign="top"
          style="
            margin: 0;
            padding: 0px;
            font-family: 'ProximaNova', sans-serif;
          "
        >
          <img src="https://leads.conexsys.com/assets/img/emailHeader.png" />
          <br />
          <h3>
            Thank you for ordering CONEXSYS Lead Retrieval services for
            ${eventName}
          </h3>
          <div>
            <hr
              style="border: 2px solid #eaeef3; border-bottom: 0; margin: 20px"
            />
          </div>
        </td>
      </tr>
      <tr>
        <td
          valign="top"
          style="
            margin: 0;
            padding: 20px;
            font-family: 'ProximaNova', sans-serif;
            height: 100% !important;
          "
        >
          <div>
            <p>
              To access your lead retrieval portal and complete your account
              setup, please click the Login/Create Conexsys Leads Account button
              below.
            </p>
			<p>
              All leads you scan during the event will be available in this
              portal 24/7. You can view & download your leads at any time by
              logging into your lead portal account.
            </p>
            <p align="center">
              <a
                href="https://leads.conexsys.com/activate?serviceId=${service.id}&contextId=${context.id}"
                target="_blank"
                style="
                  width: 300px;
                  padding: 7px;
                  cursor: pointer;
                  background: #00a2eb;
                  color: #fff;
                  border-radius: 5px;
                  border: 1px solid #00a2eb;
                  text-decoration: none;
                "
                >Login/Create Conexsys Leads Account</a
              >
            </p>
			${mobileLicenseContent}
			${opticonLicenseContent}
			${sstLicenseContent}
			${apiLicenseContent}          
            <hr
              style="
                border: 2px solid #eaeef3;
                border-bottom: 0;
                margin: 20px 0;
              "
            />
            <p style="text-align: center; color: #a9b3bc">
              If you are having any issues with your account, please don't
              hesitate to contact our <a target="_blank" href="https://tawk.to/ConexsysLeads">Support Team</a>.
            </p>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>`
			});

			if (!response) throw 'Response missing from call';

			if (response.status < 200 || response.status >= 300) {
				if (onError) onError(`${response.status}: Email Send Invalid Response`);
			} else {
				if (onSuccess) onSuccess(response.data);
			}
			return response.data;
		} catch (e) {
			console.error(e);
			if (onError)
				onError(`Error attempting to send email: ${e?.message || e}`);
		}
	}

	async sendPostShowEmail(
		ctx: UseCtx<any>,
		service: ServiceEntity,
		context: ContextEntity,
		contact: ContactOrganizationEntity,
		to: string,
		cc?: string,
		bcc?: string,
		onError?: (message: string) => void,
		onSuccess?: (result: any) => void
	): Promise<any> {
		https: try {
			let eventName: string = context.name || '';
			const response = await ctx.app.api.sendEmail({
				from: 'CONEXSYS Lead Retrieval<no-reply@conexsysleads.com>',
				sender: 'no-reply@conexsysleads.com',
				to,
				cc,
				bcc,
				subject: 'Lead Retrieval – Post Show Email',
				html: `<div>
  <table
    style="
      width: 650px;
      margin: 0;
      padding: 0;
      font-family: 'ProximaNova', sans-serif;
      height: 100% !important;
      border: 1px solid #000;
    "
    border="0"
    cellpadding="0"
    cellspacing="0"
    height="100%"
    width="100%"
    id="m_5446877877469990694m_3643126213743891450bodyTable"
  >
    <tbody>
      <tr>
        <td
          align="center"
          valign="top"
          style="
            margin: 0;
            padding: 0px;
            font-family: 'ProximaNova', sans-serif;
          "
        >
          <img src="https://leads.conexsys.com/assets/img/emailHeader.png" />
          <div>
            <hr
              style="border: 2px solid #eaeef3; border-bottom: 0; margin: 20px"
            />
          </div>
        </td>
      </tr>
      <tr>
        <td
          valign="top"
          style="
            margin: 0;
            padding: 20px;
            font-family: 'ProximaNova', sans-serif;
            height: 100% !important;
          "
        >
          <div>
			<p>
			${service.name || ''}<br />
			${contact.contactFirstName || ''} ${contact.contactLastName || ''}
			<br />
			</p>
            <p>Dear ${contact.contactFirstName || 'Lead Customer'},</p>
            <p>
              Thank you for using CONEXSYS Lead Retrieval Services for your
              booth at ${eventName}. Each badge you scanned during the
              event can be viewed in your Lead Portal.
            </p>
            <p align="center">
              <br />
              <a
                href="https://leads.conexsys.com/activate?serviceId=${
									service.id
								}&contextId=${context.id}"
                target="_blank"
                style="
                  width: 300px;
                  padding: 7px;
                  cursor: pointer;
                  background: #00a2eb;
                  color: #fff;
                  border-radius: 5px;
                  border: 1px solid #00a2eb;
                  text-decoration: none;
                "
                >Click Here to Access Your Lead Portal</a
              >
            </p>
            <p>
              <br />
              Do not access this link on public computers that will retain the
              link in its memory as it is a direct link into your account.
            </p>
            <p>
              If you have any questions, please email
              <a href="mailto:order@conexsys.com">order@conexsys.com</a>.
            </p>
            <p>We look forward to seeing you again at the next show!</p>

            <hr
              style="
                border: 2px solid #eaeef3;
                border-bottom: 0;
                margin: 20px 0;
              "
            />

            <p style="text-align: center; color: #a9b3bc">
              If you are having any issues with your account, please don't
              hesitate to contact our
              <a target="_blank" href="https://tawk.to/ConexsysLeads"
                >Support Team</a
              >.
            </p>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>`
			});

			if (!response) throw 'Response missing from call';

			if (response.status < 200 || response.status >= 300) {
				if (onError) onError(`${response.status}: Email Send Invalid Response`);
			} else {
				if (onSuccess) onSuccess(response.data);
			}
			return response.data;
		} catch (e) {
			console.error(e);
			if (onError)
				onError(`Error attempting to send email: ${e?.message || e}`);
		}
	}

	async sendMobileLicenseEmail(
		ctx: UseCtx<any>,
		service: ServiceEntity,
		context: ContextEntity,
		licenses: LicenseEntities,
		to: string,
		cc?: string,
		bcc?: string,
		onError?: (message: string) => void,
		onSuccess?: (result: any) => void
	): Promise<any> {
		https: try {
			let eventName: string = context.name || '';
			const response = await ctx.app.api.sendEmail({
				from: 'CONEXSYS Lead Retrieval<no-reply@conexsysleads.com>',
				sender: 'no-reply@conexsysleads.com',
				to,
				cc,
				bcc,
				subject: 'CONEXSYS Lead License Codes - ' + service.name?.trim(),
				html: `
<div>
  <table
    style="
      width: 650px;
      margin: 0;
      padding: 0;
      font-family: 'ProximaNova', sans-serif;
      height: 100% !important;
      border: 1px solid #000;
    "
    border="0"
    cellpadding="0"
    cellspacing="0"
    height="100%"
    width="100%"
    id="m_5446877877469990694m_3643126213743891450bodyTable"
  >
    <tbody>
      <tr>
        <td
          align="center"
          valign="top"
          style="
            margin: 0;
            padding: 0px;
            font-family: 'ProximaNova', sans-serif;
          "
        >
          <img src="https://leads.conexsys.com/assets/img/emailHeader.png" />
          <br />
          <h3>
            CONEXSYS Lead Retrieval Licenses for
            ${eventName}
          </h3>
          <div>
            <hr
              style="border: 2px solid #eaeef3; border-bottom: 0; margin: 20px"
            />
          </div>
        </td>
      </tr>
      <tr>
        <td
          valign="top"
          style="
            margin: 0;
            padding: 20px;
            font-family: 'ProximaNova', sans-serif;
            height: 100% !important;
          "
        >
          <div>
            <p>
              To access your lead retrieval app portal and complete your account
              setup, please click the Activate Conexsys Leads Account button
              below.
              <b>Please Note:</b> All onsite reps will need to create an account
              in order to activate their scanning devices and/or access the
              portal.
            </p>
			<p>
              All leads you scan during the event will be available in this
              portal 24/7. You can view & download your leads at any time by
              logging into your lead portal account.
            </p>
            <hr
              style="
                border: 2px solid #eaeef3;
                border-bottom: 0;
                margin: 20px 0;
              "
            />
            <p>
			  <br />
              <table cellspacing="0" cellpadding="0" border="0" width="600">
			  ${licenses.map(l => {
					return `<tr>
					<td width='180'>
						<img src='https://myconexsys.com/Home/GenerateQRCode?data=${l.id}' width='150' />
					</td>
					<td>
						<strong>Activation Code:${l.id}</strong><br />
						<strong>Usage:${l.quantity}</strong><br />
					</td>
				</tr>`;
				})}
				</table>
            </p>
            
            <p style="text-align: center; color: #a9b3bc">
              If you are having any issues with your account, please don't
              hesitate to contact our <a target="_blank" href="https://tawk.to/ConexsysLeads">Support Team</a>.
            </p>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>`
			});

			if (!response) throw 'Response missing from call';

			if (response.status < 200 || response.status >= 300) {
				if (onError) onError(`${response.status}: Email Send Invalid Response`);
			} else {
				if (onSuccess) onSuccess(response.data);
			}
			return response.data;
		} catch (e) {
			console.error(e);
			if (onError)
				onError(`Error attempting to send email: ${e?.message || e}`);
		}
	}

	async activate(
		ctx: UseCtx<any>,
		serviceId: string,
		contextId: string,
		onError: (message: string) => void,
		onSuccess?: (result: ServiceEntity) => void
	): Promise<ServiceEntity | undefined> {
		let userId: string = ctx.app.activeUser?.userId || '';
		if (userId === '') {
			onError(
				'Unable to activate without a valid user account. Please Sign-in'
			);
			return;
		}

		if (!serviceId) {
			onError('Unable to activate without a valid service account.');
			return;
		}

		let errorMessage;
		let services = (await readServicesAll(ctx, {
			userId: userId,
			serviceType: ServiceType.Organization,
			ids: [serviceId]
		})
			.then((result: ServiceEntities) => {
				return result;
			})
			.catch(e => {
				errorMessage =
					'Service request error: ' + e.response?.data?.message || e.message;
				return;
			})) as ServiceEntities;

		if (errorMessage) {
			onError(errorMessage);
			return;
		}

		let service = services[0];
		if (!service) {
			onError('Unable to activate without a valid service account.');
			return;
		}

		let licenses = (await readLicensesAll(ctx, {
			userId: userId,
			contextIds: [contextId],
			serviceIds: [serviceId]
		})
			.then((result: LicenseEntities) => {
				return result;
			})
			.catch(e => {
				errorMessage =
					'License request error: ' + e.response?.data?.message || e.message;
				return;
			})) as LicenseEntities;

		if (errorMessage) {
			onError(errorMessage);
			return;
		}

		if (!licenses || licenses.length <= 0) {
			onError('Unable to activate without a valid license.');
			return;
		}

		if (!service.userIds?.includes(userId)) {
			service.userIds?.push(userId);

			let updatedServices = (await writeServices(ctx, {
				userId: userId,
				services: [service]
			})
				.then((result: ServiceEntities) => {
					return result;
				})
				.catch(e => {
					errorMessage =
						'Service update error: ' + e.response?.data?.message || e.message;
					return;
				})) as ServiceEntities;

			if (errorMessage) {
				onError(errorMessage);
				return;
			}

			if (updatedServices && updatedServices.length > 0) {
				service = updatedServices[0];
			}
		}

		licenses.forEach(license => {
			if (!license.userIds?.includes(userId)) {
				license.userIds?.push(userId);
			}
		});

		let updatedLicenses = (await writeLicenses(ctx, {
			userId: userId,
			licenses: licenses
		})
			.then((result: LicenseEntities) => {
				return result;
			})
			.catch(e => {
				errorMessage =
					'License update error: ' + e.response?.data?.message || e.message;
				return;
			})) as LicenseEntities;

		if (errorMessage) {
			onError(errorMessage);
			return;
		}

		if (onSuccess) onSuccess(service);
		return service;
	}

	// Exporting services
	async exportData(
		ctx: UseCtx<any>,
		context: ContextEntity,
		services: ServiceEntities,
		onUpdate?: (message: string) => void,
		onError?: (message: string) => void,
		onSuccess?: () => void
	): Promise<any> {
		if (onUpdate) {
			onUpdate('Building Data Set...');
		}

		// All data is now loaded
		if (onUpdate) {
			onUpdate('Generating File...');
		}

		let workbook = new Excel.Workbook();
		let worksheet = workbook.addWorksheet('Services');
		let cols = [
			{
				header: 'Company',
				key: 'name'
			},
			{
				header: 'Address1',
				key: 'address1'
			},
			{
				header: 'Address2',
				key: 'address2'
			},
			{
				header: 'City',
				key: 'city'
			},
			{
				header: 'State/Prov',
				key: 'subdivision'
			},
			{
				header: 'Postal/Zip',
				key: 'postal'
			},
			{
				header: 'Country',
				key: 'country'
			},
			{
				header: 'First Name',
				key: 'contactFirstName'
			},
			{
				header: 'Last Name',
				key: 'contactLastName'
			},
			{
				header: 'Email',
				key: 'contactEmail'
			},
			{
				header: 'Licenses Used',
				key: 'licensesused'
			},
			{
				header: 'Leads Scanned',
				key: 'leadsscanned'
			}
		];

		worksheet.columns = cols;
		worksheet.columns.forEach(column => {
			if (column.header) {
				column.width = column.header.length < 15 ? 15 : column.header.length;
			}
		});

		services!
			.sort((a: ServiceEntity, b: ServiceEntity) =>
				(a?.name ?? '').toLocaleLowerCase() >
				(b?.name ?? '').toLocaleLowerCase()
					? 1
					: -1
			)
			.forEach(item => {
				let service = item;
				let contact = ctx.lead.contact.get_Organization(
					service?.contactIds ? service.contactIds[0] ?? '' : ''
				);
				let licenses = ctx.lead.license
					.allByServices([service])
					?.filter(l => l.contextId === context.id);
				let activeDevices = licenses
					?.filter(license => {
						if ((license.deviceIds?.length || 0) <= 0) {
							return false;
						}
						return true;
					})
					.reduce((sum, current) => sum + (current.deviceIds?.length ?? 0), 0);

				let leadsScanned = ctx.lead.lead.allByServiceIdAndEventIds(
					service.id,
					context?.eventIds ?? []
				).length;

				worksheet.addRow(
					{
						...service,
						...contact,
						licensesused: activeDevices,
						leadsscanned: leadsScanned
					},
					''
				);
			});

		const buffer = await workbook.xlsx.writeBuffer();
		const fileType =
			'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
		const fileExtension = '.xlsx';

		const blob = new Blob([buffer], {
			type: fileType
		});

		saveAs(
			blob,
			(context!.name || 'Context') +
				'Companies-' +
				moment().format('YYYY-MM-DD-hmm') +
				fileExtension
		);

		if (onUpdate) {
			onUpdate('Complete');
		}
		if (onSuccess) {
			onSuccess();
		}
	}
}
