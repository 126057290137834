import React, { Props, useState } from 'react';

//DATA
import {
	LicenseEntity,
	LicenseEntities,
	ServiceEntity,
	EventEntity
} from '../../../store';
import { useCtx } from '../../../../config/hooks';

//UI
import {
	IonGrid,
	IonRow,
	IonCol,
	IonList,
	IonItem,
	IonTitle,
	IonIcon,
	IonLabel,
	IonButton
} from '@ionic/react';
import { LicenseCard } from '../../components/License/LicenseCard';
import { cloudDoneOutline } from 'ionicons/icons';
import uuid from '../../../../app/utils/uuid';
export interface LicenseCardListConfigProps {
	title?: string;
	finish?: any;
	licenseType?: string;
	service: ServiceEntity;
	event: EventEntity;
	licenses: LicenseEntities;
}

export const LicenseCardList: React.FC<LicenseCardListConfigProps> = props => {
	const ctx = useCtx(); // TO DO: create and set (deviceListConfig)
	const {
		config: [config],
		app: { activeUser }
	} = ctx;

	const sortLicenses = (a: LicenseEntity, b: LicenseEntity) => {
		return a.i_.status > b.i_.status ? 1 : b.i_.status > a.i_.status ? -1 : 0;
		/*return (a.i_.created?.dt as Date) > (b.i_.created?.dt as Date)
			? 1
			: (b.i_.created?.dt as Date) > (a.i_.created?.dt as Date)
			? -1
			: 0;*/
	};
	let licenses: LicenseEntities = props.licenses.filter(
		license => license.serviceType === props.licenseType || ''
	);

	const licenseListItems = licenses
		.sort(sortLicenses)
		.map(
			(license: LicenseEntity, index: number, licenseList: LicenseEntity[]) => {
				return (
					<IonItem key={'IonItem_' + license.id}>
						<LicenseCard key={'LicenseCard_' + license.id} license={license} />
					</IonItem>
				);
			}
		);

	return licenses.length <= 0 ? (
		<h2></h2>
	) : (
		<IonGrid>
			<IonRow>
				<IonCol sizeSm="3">
					<IonTitle>{props.title}</IonTitle>
					<div
						style={{ textAlign: 'center' }}
						hidden={props.licenseType !== 'lead-app'}
					>
						<img
							style={{ display: 'inline-block', textAlign: 'center' }}
							width="45%"
							src={'assets/img/myleads_app.png'}
						/>
						<div style={{ display: 'block', textAlign: 'center' }}>
							<IonButton
								target="_blank"
								href="https://leads.conexsys.com/app"
								size='small'
								color='tertiary'
							>
								Download the app
							</IonButton>
						</div>
					</div>
					<div
						style={{ textAlign: 'center' }}
						hidden={props.licenseType !== 'lead-api'}
					>
						<IonIcon src={cloudDoneOutline} style={{ zoom: 9.0 }} />
					</div>
					<div
						style={{ textAlign: 'center' }}
						hidden={props.licenseType !== 'sst'}
					>
						<img
							style={{ display: 'inline-block', textAlign: 'center' }}
							width="50%"
							src={'assets/img/bc500dc.jpg'}
						/>
					</div>
					<div
						style={{ textAlign: 'center' }}
						hidden={props.licenseType !== 'opticon'}
					>
						<img
							style={{ display: 'inline-block', textAlign: 'center' }}
							width="50%"
							src={'assets/img/Opticon_9725.jpg'}
						/>
						<br />
						<IonLabel hidden={!activeUser?.security.includes('administrator')}>
							<strong>Sign-Out Code:</strong>
							<br />
							{'S%' + uuid().compress(props.service?.id || '')}
						</IonLabel>
					</div>
				</IonCol>
				<IonCol sizeSm="9">
					<IonList key="licenseList">{licenseListItems}</IonList>
				</IonCol>
			</IonRow>
		</IonGrid>
	);
};
